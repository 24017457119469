import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Quiz } from '../../models/quiz.model';
import { GlobalModalService } from '@app/core/services/global-modal.service';

@Component({
  selector: 'app-import-quiz',
  templateUrl: './import-quiz.component.html',
  styleUrls: ['./import-quiz.component.sass'],
})
export class ImportQuizComponent {
  files?: File[];
  quiz!: Quiz;

  constructor(
    private router: Router,
    private localModalService: GlobalModalService
  ) {}

  processFile() {
    if (this.files && this.files[0]) {
      this.processJsonFile(this.files[0]).subscribe({
        next: (response) => {
          this.quiz = response;
          this.router.navigate(['/course-composer/quiz-creator/create'], {
            state: { data: this.quiz },
          });
        },
        error: (error) => {
          this.localModalService.alert({
            title: 'Import Quiz',
            content: error,
            type: 'danger',
            okButtonText: 'Ok',
          });
          console.error('Error processing file', error);
        },
      });
    } else {
      console.error('Empty file selected');
    }
  }

  private processJsonFile(file: File): Observable<Quiz> {
    return new Observable<Quiz>((subscriber) => {
      const reader = new FileReader();
      reader.onload = () => {
        const json = JSON.parse(reader.result as string);
        if (this.isValidQuiz(json)) {
          subscriber.next(json);
        } else {
          subscriber.error('Invalid quiz format.');
        }
      };
      reader.readAsText(file);
    });
  }

  private isValidQuiz(json: any): json is Quiz {
    if (typeof json !== 'object' || json === null) return false;
    if (typeof json.title !== 'string' || json.title.trim() === '')
      return false;
    if (typeof json.description !== 'string' || json.description.trim() === '')
      return false;
    if (!Array.isArray(json.questions) || json.questions.length === 0)
      return false;

    return true;
  }
}
