import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { Quiz } from '@app/api/quiz/models/quiz.model';
import { Page } from '@app/api/models/page.model';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(private http: HttpClient, private api: ApiService) { }

  getQuizById(id: number) {
    return this.http.get<Quiz>(this.api.endpoint(['quizzes', id]));
  }

  getQuizzesByOwner(ownerId: number | 'self') {
    return this.http.get<Page<Quiz>>(this.api.endpoint(['/quizzes', 'owner', ownerId]));
  }

  createQuiz(quiz: Quiz) {
    return this.http.post<Quiz>(this.api.endpoint('quizzes'), quiz);
  }

  updateQuiz(id: number, quiz: Quiz) {
    return this.http.put<Quiz>(this.api.endpoint(['quizzes', id]), quiz);
  }

  deleteQuiz(id: number) {
    return this.http.delete(this.api.endpoint(['quizzes', id]));
  }

}
