import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { LessonData } from '@app/api/models/learning-material-data.model';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { LessonApiService } from '@app/api/lesson/services/lesson-api.service';
import { Subscription, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LearningRouteDataService } from '@app/learning/services/learning-route-data.service';

@Component({
  selector: 'app-lesson-activity-view',
  templateUrl: './lesson-activity-view.component.html',
  styleUrls: ['./lesson-activity-view.component.sass']
})
export class LessonActivityViewComponent implements OnInit, OnDestroy {

  @Input() lessonActivityId?: number;
  lessonActivity?: LearningActivity;
  lesson?: LessonData;
  learningActivitySubscription?: Subscription;

  @Input() showNavigation = true;

  loading = false;

  constructor(
    private route: ActivatedRoute,
    public learningRouteData: LearningRouteDataService,
    private learningActivityService: LearningActivityService,
    private lessonApi: LessonApiService) {
    this.route.paramMap.subscribe(params => {

      this.learningRouteData.updateRouteData(params);

      let lessonId = params.get('lessonId');

      if (!lessonId) {
        lessonId = this.lessonActivityId?.toString() || null;
      }

      if (lessonId) {
        this.lessonActivityId = Number(lessonId);
        this.loadLessonActivity(this.lessonActivityId);
      }
    });
  }

  ngOnInit() {
    if (this.lessonActivityId) {
      this.loadLessonActivity(this.lessonActivityId);
    }
  }

  loadLessonActivity(lessonActivityId: number) {
    this.loading = true;

    if (this.learningActivitySubscription) {
      this.learningActivitySubscription.unsubscribe();
    }

    this.learningActivitySubscription = this.learningActivityService.getLearningActivity(lessonActivityId)
      .pipe(
        switchMap(lessonActivity => {
          this.lessonActivity = lessonActivity;
          return this.lessonApi.getLesson(lessonActivity.materialId);
        })
      )
      .subscribe({
        next: lesson => {
          this.lesson = lesson;
          this.loading = false;
        }
      });
  }

  unloadLessonActivity() {
    this.lessonActivity = undefined;
    this.lesson = undefined;

    if (this.learningActivitySubscription) {
      this.learningActivitySubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unloadLessonActivity();
  }

}
