import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { Router } from '@angular/router';
import { GlobalModalService } from '@core/services/global-modal.service';

@Component({
  selector: 'app-open-assignments',
  templateUrl: './open-assignments.component.html',
  styleUrls: ['./open-assignments.component.sass']
})
export class OpenAssignmentsComponent implements OnInit {

  @Output() openAssignment = new EventEmitter<number>();
  @Input() currentAssignmentId?: number;
  @Input() showTitle = true;
  assignments: AssignmentData[] = [];
  loading = false;

  constructor(private assignmentApi: AssignmentApiService,
    private modal: GlobalModalService,
    private router: Router) {
  }

  ngOnInit() {
    this.loading = true;
    this.assignmentApi.getAssignmentsByOwner('self').subscribe(page => {
      this.assignments = page.content.filter(a => a.id !== this.currentAssignmentId);
      this.loading = false;
    });
  }

  truncateText(title: string, limit = 10) {
    if (title.length > limit) {
      return title.substring(0, limit) + '...';
    }
    return title;
  }

  onOpenAssignment(id: number) {
    this.router.navigate(['/assignment-composer', id]);
    if (this.openAssignment) {
      this.openAssignment.emit(id);
    }
  }

  deleteAssignment(id: number) {
    this.modal.confirm('Are you sure you want to delete this assignment and learning materials under this assignment?', {
      title: 'Delete Assignment',
      iconClass: 'bi bi-trash text-danger',
      type: 'danger',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.assignmentApi.deleteAssignment(id).subscribe({
            next: () => {
              this.assignments = this.assignments.filter(a => a.id !== id);
            }
          });
        }
      }
    });
  }

}
