<div class="container">
  <div *ngIf="showTitle" class="my-2">
    <h2>My Assignments</h2>
  </div>
  <div class="my-3">
    <div *ngIf="!loading; else spinner" class="row row-cols-3 row-cols-md-4">
      <div *ngFor="let assignment of assignments" class="col my-3">
        <div tabindex="0" class="card card-body h-100 assignment-card" (click)="onOpenAssignment(assignment.id!)">
          <div class="d-flex flex-row align-items-center h-100">
            <div class="bi bi-file-earmark-text text-primary display-6"></div>
            <div class="mx-2 flex-grow-1">
              <div class="fw-bold text-wrap">{{truncateText(assignment.title, 15) || 'Untitled Assignment'}}</div>
              <p class="text-muted">{{truncateText(assignment.description, 25) || 'No description'}}</p>
            </div>
            <div class="h-100 align-top" ngbDropdown placement="right-top" click-stop-propagation>
              <button ngbDropdownToggle class="btn btn-sm btn-link text-primary border-0 dropdown-no-caret" click-stop-propagation>
                <span class="bi bi-three-dots-vertical"></span>
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem>
                  <div class="d-flex justify-content-between" (click)="onOpenAssignment(assignment.id!)">
                    Edit <span class="bi bi-pencil-square"></span>
                  </div>
                </button>
                <button ngbDropdownItem (click)="deleteAssignment(assignment.id!)">
                  <div class="d-flex justify-content-between">
                    Delete <span class="bi bi-trash text-danger"></span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <div class="row row-cols-1">
    <div class="col d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</ng-template>
