<div *ngIf="moduleData" class="my-2">

  <div class="mb-3">
    <h4>Module Details</h4>
    <p class="text-muted small">A module is a comprehensive collection of lessons to teach a larger topic.</p>
  </div>

  <div class="form-floating my-2">
    <input type="text" class="form-control" id="module-title" placeholder="Module Title" [(ngModel)]="moduleData.title">
    <label for="module-title" class="form-label">Module Title</label>
  </div>

  <div class="form-floating my-2">
    <textarea class="form-control" placeholder="Module Description" id="module-description" style="height: 100px" [(ngModel)]="moduleData.description" [formControl]="moduleDescriptionControl" (input)="onDescriptionInput()"></textarea>
    <label for="module-description">Module Description</label>
    <div class="d-flex justify-content-between align-items-center">
      <app-control-validation-message [control]="moduleDescriptionControl"></app-control-validation-message>
      <span class="flex-grow-3"></span>
      <span [ngClass]="{'text-danger': isDescriptionLimitExceeded()}">
        {{ moduleDescriptionControl.value.length }}/{{ maxDescriptionLength }}
      </span>
    </div>
  </div>

  <div class="my-3">

    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-grow-1">
        <h4>Lessons</h4>
        <p class="text-muted small">Add lessons to this module in the order it is to be presented.</p>
      </div>
      <div *ngIf="moduleData.lessons && moduleData.lessons.length" class="text-end d-flex align-items-center justify-content-center">
        <button class="btn btn-primary mx-2" (click)="openAddLessonModal()"><span class="bi bi-bookmark-plus"></span> Add Lesson</button>
        <p *ngIf="disableCreateLesson" class="my-0 mx-2">Or</p>
        <button *ngIf="disableCreateLesson" type="button" class="btn btn-primary mx-2" (click)="createLesson()">
          <span class="bi bi-file-earmark-plus"></span>
          Create Lesson
        </button>
      </div>
    </div>

    <div class="border rounded my-2 bg-light">
      <ul *ngIf="moduleData.lessons && moduleData.lessons.length; else noLessons"
          cdkDropList
          (cdkDropListDropped)="onMoveItem($event)"
          [cdkDropListData]="moduleData.lessons" class="list-group list-group-flush rounded">
        <li cdkDrag [cdkDragPreviewContainer]="previewContainer" *ngFor="let lesson of orderedLessons" class="list-group-item list-group-item-action px-0 bg-white lesson-item">
          <div class="d-flex align-items-center">
            <button type="button" class="drag-drop-handle" cdkDragHandle>
              <app-icon iconName="six-dots" class="svg-muted"></app-icon>
            </button>
            <div class="flex-shrink-1 px-3">
              <span class="badge bg-primary rounded-circle">{{lesson.order + 1}}</span>
            </div>
            <div class="me-2 w-100">
              <div class="mb-2">
                <div class="fw-bold">{{lesson.title}}</div>
                <div class="d-flex gap-1 flex-wrap">
                  <span *ngFor="let technology of lesson.technologies" class="badge bg-info rounded-pill">{{technology}}</span>
                </div>
              </div>
              <div class="text-muted small text-wrap">
                {{lesson.description}}
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center gap-1 w-100 pe-3 flex-shrink-1">
              <button class="btn btn-sm btn-outline-primary" (click)="openEditLessonPage(lesson)">
                <span class="bi bi-pencil"></span>
                <span class="visually-hidden">Edit Lesson</span>
              </button>
              <button class="btn btn-sm btn-outline-primary" (click)="openPreviewLessonModal(lesson)">
                <span class="bi bi-eye"></span>
                <span class="visually-hidden">Preview Lesson</span>
              </button>
              <button class="btn btn-sm btn-outline-danger" (click)="removeLesson(lesson)">
                <span class="bi bi-trash"></span>
                <span class="visually-hidden">Remove from Module</span>
              </button>
            </div>
          </div>
        </li>
      </ul>
      <ng-template #noLessons>
        <div class="py-5 text-center">
          No lessons have been added to this module yet.
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <button class="btn btn-primary mx-2" (click)="openAddLessonModal()">
              <span class="bi bi-bookmark-plus"></span> Add Lesson
            </button>
            <p *ngIf="disableCreateLesson" class="my-0 mx-2">Or</p>
            <button *ngIf="disableCreateLesson" type="button" class="btn btn-primary mx-2" (click)="createLesson()">
             <span class="bi bi-file-earmark-plus"></span>
            Create Lesson
            </button>
          </div>
        </div>
      </ng-template>
    </div>

  </div>

</div>

<ng-template #addLessonModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add Lesson to Module</h5>
    <button type="button" class="btn-close" (click)="modal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <app-learning-material-selector [publishedOnly]="true" (selectLesson)="onSelectLesson($event)" materialType="LESSON" [materialFilter]="alreadySelectedFilter"></app-learning-material-selector>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="addLesson(); modal.close()">Add Lesson</button>
  </div>
</ng-template>

<ng-template #previewLessonModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Preview Lesson</h5>
    <button type="button" class="btn-close" (click)="modal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <app-lesson-preview [lessonData]="previewLessonData!"></app-lesson-preview>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">Close</button>
  </div>
</ng-template>
