import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { LearningMaterialData, LessonAssignmentData, LessonData } from '@app/api/models/learning-material-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@app/api/user/services/user.service';
import { FileRecord } from '@core/components/file-explorer/file-record.model';
import { FilesApiService } from '@core/services/files-api.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';

@Component({
  selector: 'app-lesson-form',
  templateUrl: './lesson-form.component.html',
  styleUrls: ['./lesson-form.component.sass']
})
export class LessonFormComponent implements OnInit {

  @ViewChild('addAssignmentModal') addAssignmentModal!: TemplateRef<any>;
  @ViewChild('selectLectureSlideModal') selectLectureSlideModal!: TemplateRef<any>;

  @Input() lessonData!: LessonData;
  @Input() maxDescriptionLength: number = 250;
  @Output() lessonDataChange = new EventEmitter<LessonData>();
  @Output() triggerSaveLesson = new EventEmitter();
  userEmail = '';
  presentationFileTypes = ['application/vnd.ms-powerpoint', 'application/pdf', 'text/markdown', 'text/plain'];
  presentationFileRecord: FileRecord | null = null;
  modalSelectedAssignment: AssignmentData | null = null;
  lessonDescriptionControl!: FormControl;
  disableCreateAssignment : boolean = true;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private filesApi: FilesApiService,
    private route: ActivatedRoute,
    private assignmentApiService: AssignmentApiService,
    private router : Router
  ) {
    if (this.userService.currentUser) {
      this.userEmail = this.userService.currentUser.email;
    }
  }

  ngOnInit(): void {
    if (!this.lessonData.assignments) {
      this.lessonData.assignments = [];
    }

    if (!this.lessonData.lessonAttachments) {
      this.lessonData.lessonAttachments = [];
    } else {
      // Find first presentation attachment
      const presentationAttachment = this.lessonData.lessonAttachments.find(attachment => attachment.attachmentType === 'PRESENTATION');
      if (presentationAttachment) {
        this.filesApi.getFileRecord(presentationAttachment.fileRecordId).subscribe({
          next: (fileRecord) => {
            this.presentationFileRecord = fileRecord;
          }
        });
      }
    }
    this.lessonDescriptionControl = new FormControl(this.lessonData.description, [
      Validators.required,
      Validators.maxLength(this.maxDescriptionLength) 
    ]);
    this.lessonDescriptionControl.valueChanges.subscribe(value => {
      this.lessonData.description = value;
    });
    this.updateAssignmentOrder();

    this.route.queryParams.subscribe((params) => {
      if (params['assignmentId']) {
        this.assignmentApiService.getAssignment(params['assignmentId']).subscribe({
          next: (assignment) => {
            this.modalSelectedAssignment = assignment;
            this.addAssignment();
            this.router.navigate([], {
              queryParams: {
                'assignmentId': null
              },
              queryParamsHandling: 'merge'
            })
          },
        });
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params['moduleId']) {
        this.disableCreateAssignment = false;
      }
    });
  }

  onDescriptionInput() {
    this.lessonDescriptionControl.markAsTouched();
    this.lessonDescriptionControl.updateValueAndValidity();
  }

  isDescriptionLimitExceeded(): boolean {
    return this.lessonDescriptionControl.value.length > this.maxDescriptionLength;
  }  

  onMoveItem(event: any): void {
    moveItemInArray(this.lessonData.assignments, event.previousIndex, event.currentIndex);
    this.lessonData.assignments[event.currentIndex].order = event.currentIndex;
    this.updateAssignmentOrder();
    this.lessonDataChange.emit(this.lessonData);
  }

  updateAssignmentOrder(): void {
    this.lessonData.assignments.forEach((assignment, index) => {
      assignment.order = index;
    });
  }

  openAddAssignmentModal(): void {
    this.modalSelectedAssignment = null;
    this.modalService.open(this.addAssignmentModal, {
      size: 'lg',
      scrollable: true
    });
  }

  addAssignment(): void {
    if (this.modalSelectedAssignment) {
      this.lessonData.assignments.push({
        id: this.modalSelectedAssignment.id || 0,
        order: 0,
        title: this.modalSelectedAssignment.title || '',
        description: this.modalSelectedAssignment.description || '',
        deprecated: this.modalSelectedAssignment.deprecated || false,
        published: this.modalSelectedAssignment.published || false,
        approved: this.modalSelectedAssignment.approved || false,
        technologies: this.modalSelectedAssignment.technologies || [],
        submissionTypes: this.modalSelectedAssignment.submissionTypes || [],
      });
      this.updateAssignmentOrder();
      this.lessonDataChange.emit(this.lessonData);
    }
  }

  removeAssignment(assignment: LessonAssignmentData): void {
    this.lessonData.assignments = this.lessonData.assignments.filter(a => a.id !== assignment.id);
    this.updateAssignmentOrder();
    this.lessonDataChange.emit(this.lessonData);
  }

  openSelectLectureSlideModal(): void {
    this.modalService.open(this.selectLectureSlideModal);
  }

  onFileSelected(file: FileRecord): void {
    this.presentationFileRecord = file;
    this.lessonData.lessonAttachments[0] = {
      id: 0,
      fileRecordId: file.id,
      attachmentType: 'PRESENTATION'
    };
    this.modalService.dismissAll();
  }

  removePresentationAttachment(): void {
    this.lessonData.lessonAttachments = [];
    this.presentationFileRecord = null;
  }

  onSelectAssignment(assignment: AssignmentData): void {
    this.modalSelectedAssignment = assignment;
  }

  get alreadySelectedFilter() {
    return (data: LearningMaterialData) => {
      return !this.lessonData.assignments.find(a => a.id === data.id);
    };
  }
  
  createAssignment(): void {
    this.triggerSaveLesson.emit();
  }
}
