import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { LearningMaterialData, LessonData, ModuleData } from '@app/api/models/learning-material-data.model';
import { moveItemInArray, PreviewContainer } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LessonApiService } from '@app/api/lesson/services/lesson-api.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ModuleApiService } from '@app/api/module/services/module-api.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-module-form',
  templateUrl: './module-form.component.html',
  styleUrls: ['./module-form.component.sass']
})
export class ModuleFormComponent implements OnInit {

  @ViewChild('previewLessonModal') previewLessonModal!: TemplateRef<any>;
  @ViewChild('addLessonModal') addLessonModal!: TemplateRef<any>;

  @Input() previewContainer: PreviewContainer = 'global';
  @Input() moduleData!: ModuleData;
  @Input() maxDescriptionLength: number = 250;
  @Output() moduleDataChange = new EventEmitter<ModuleData>();
  @Output() editLesson = new EventEmitter<LessonData>();
  @Output() triggerSaveModule = new EventEmitter();

  selectedLesson: LessonData | null = null;
  previewLessonData: LessonData | null = null;
  moduleDescriptionControl! : FormControl;
  disableCreateLesson : boolean = true;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route : ActivatedRoute,
    private moduleApi: ModuleApiService,
    private globalModal: GlobalModalService,
    private lessonApi: LessonApiService) {
  }

  addLesson() {
    if (!this.moduleData.lessons) {
      this.moduleData.lessons = [];
    }

    if (this.selectedLesson) {
      this.lessonApi.getLesson(this.selectedLesson.id!).subscribe({
        next: (lessonData) => {
          this.moduleData.lessons.push(lessonData);
          this.moduleDataChange.emit(this.moduleData);
          this.updateLessonOrder();
        }
      });
    }
  }

  ngOnInit() {
    this.updateLessonOrder();

    if (this.moduleData.lessons && this.moduleData.lessons.length) {
      for (let i = 0; i < this.moduleData.lessons.length; i++) {
        this.lessonApi.getLesson(this.moduleData.lessons[i].id!).subscribe({
          next: (lessonData) => {
            this.moduleData.lessons[i] = lessonData;
          }
        });
      }
    }
    this.moduleDescriptionControl = new FormControl(this.moduleData.description, [
      Validators.required,
      Validators.maxLength(250)
    ]);
    this.moduleDescriptionControl.valueChanges.subscribe(value => {
      this.moduleData.description = value;
    });
    this.route.queryParams.subscribe((params) => {
      if (params['lessonId']) {
        this.lessonApi.getLesson(params['lessonId']).subscribe({
          next: (lesson) => {
            this.selectedLesson = lesson;
            this.addLesson();
            this.router.navigate([], {
              queryParams: {
                lessonId: null,
              },
              queryParamsHandling: 'merge',
            });
          },
        });
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params['courseId']) {
        this.disableCreateLesson = false;
      }
    });
  }

  onDescriptionInput() {
    this.moduleDescriptionControl.markAsTouched();
    this.moduleDescriptionControl.updateValueAndValidity();
  }

  isDescriptionLimitExceeded(): boolean {
    return this.moduleDescriptionControl.value.length > this.maxDescriptionLength;
  }  

  onMoveItem(event: any): void {
    moveItemInArray(this.moduleData.lessons, event.previousIndex, event.currentIndex);
    this.moduleData.lessons[event.currentIndex].order = event.currentIndex;
    this.updateLessonOrder();
    this.moduleDataChange.emit(this.moduleData);
  }

  updateLessonOrder() {
    this.moduleData.lessons.forEach((lesson, index) => {
      lesson.order = index;
    });
  }

  onSelectLesson(lesson: LessonData) {
    this.selectedLesson = lesson;
  }

  removeLesson(lesson: LessonData) {
    this.globalModal.confirm('Are you sure you want to remove this lesson?', {
      title: 'Remove Lesson',
      okButtonText: 'Remove',
      type: 'danger'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.moduleData.lessons = this.moduleData.lessons.filter(l => l.id !== lesson.id);
          this.moduleDataChange.emit(this.moduleData);
          this.updateLessonOrder();
        }
      }
    });
  }

  openAddLessonModal() {
    this.selectedLesson = null;
    this.modalService.open(this.addLessonModal, {
      size: 'lg',
      scrollable: true,
    });
  }
  createLesson(){
    this.triggerSaveModule.emit();
  }

  openPreviewLessonModal(lesson: LessonData) {
    if (!lesson.id) {
      return;
    }
    this.modalService.open(this.previewLessonModal, { size: 'lg', scrollable: true });
    this.lessonApi.getLesson(lesson.id!).subscribe({
      next: (lessonData) => {
        this.previewLessonData = lessonData;
      }
    });
  }

  get alreadySelectedFilter() {
    return (data: LearningMaterialData) => {
      return !this.moduleData.lessons.some(lesson => lesson.id === data.id);
    };
  }

  openEditLessonPage(lesson: LessonData) {
    if (!lesson.id) {
      return;
    }

    this.editLesson.emit(lesson);
  }

  get orderedLessons() {
    return this.moduleData.lessons?.sort((a, b) => a.order - b.order);
  }

}
