import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-activity-skeleton-structure',
  templateUrl: './activity-skeleton-structure.component.html',
  styleUrls: ['./activity-skeleton-structure.component.sass']
})
export class ActivitySkeletonStructureComponent {

  @Input() structureType: 'courseOrModule' | 'lesson' | 'assignment' = 'courseOrModule';

}
