import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalModalComponent } from '@core/components/global-modal/global-modal.component';
import { GlobalModalDefaultOptions, GlobalModalOptions } from '@core/interfaces/global-modal-options.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalModalService {

  readonly GlobalModalDefaultOptions = GlobalModalDefaultOptions;

  constructor(private modalService: NgbModal) {}

  alert(options: GlobalModalOptions) {
    const modalRef = this.modalService.open(GlobalModalComponent, {
      centered: options.centered || GlobalModalDefaultOptions[options.type].centered,
      size: options.size || GlobalModalDefaultOptions[options.type].size,
    });
    modalRef.componentInstance.options = options;
    return modalRef;
  }

  confirm(question: string, options?: Partial<GlobalModalOptions>): Observable<boolean> {
    const modalRef = this.modalService.open(GlobalModalComponent, {
      centered: options?.centered ? options?.centered : GlobalModalDefaultOptions['confirm'].centered,
    });
    modalRef.componentInstance.options = {
      ...GlobalModalDefaultOptions['confirm'],
      ...options,
      content: question,
    };
    // Return modalRef.result as an observable
    return new Observable((observer) => {
      modalRef.result.then(() => {
        observer.next(true);
        observer.complete();
      }, () => {
        observer.next(false);
        observer.complete();
      });
    });
  }

  confirmDelete(message: string, resourceName?: string, options?: Partial<GlobalModalOptions>): Observable<boolean> {
    const modalRef = this.modalService.open(GlobalModalComponent, {
      centered: options?.centered ? options?.centered : GlobalModalDefaultOptions['confirm'].centered,
    });
    modalRef.componentInstance.options = {
      ...GlobalModalDefaultOptions['delete'],
      ...options,
      content: message,
      showInput: true,
      deleteMode: true,
      resourceName: resourceName,
    };

    return new Observable((observer) => {
      modalRef.result.then(() => {
        observer.next(true);
        observer.complete();
      }, () => {
        observer.next(false);
        observer.complete();
      });
    });
  }

  spinner(text: string, options?: Partial<GlobalModalOptions>) {
    const modalRef = this.modalService.open(GlobalModalComponent, {
      centered: options?.centered ? options?.centered : true,
      backdrop: 'static',
    });
    modalRef.componentInstance.options = {
      ...GlobalModalDefaultOptions['bodyOnly'],
      ...options,
      content: text,
      showSpinner: true,
      hideButtons: true,
      staticBackdrop: true,
    };
    return modalRef;
  }

  icon(text: string, iconClass: string, options?: Partial<GlobalModalOptions>) {
    const modalRef = this.modalService.open(GlobalModalComponent, {
      centered: options?.centered ? options?.centered : true,
    });

    modalRef.componentInstance.options = {
      ...GlobalModalDefaultOptions['bodyOnly'],
      ...options,
      content: text,
      iconClass: iconClass,
      showSpinner: false,
    };
    return modalRef;
  }
}
