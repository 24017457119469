import { Component, Input } from '@angular/core';
import { ModuleData } from '@app/api/models/learning-material-data.model';

@Component({
  selector: 'app-module-tree',
  templateUrl: './module-tree.component.html',
  styleUrls: ['./module-tree.component.sass']
})
export class ModuleTreeComponent {

  @Input() moduleData?: ModuleData;
  @Input() indent = false;
  lessonsCollapse = false;

}
