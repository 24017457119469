<div class="my-3" *ngIf="data && (data.title || data.text || data.description || data.difficulty || questionType); else previewQuestionText">

  <div class="mb-3">
    <span class="h4">
      {{data.title ? data.title: 'Untitled Question'}}
    </span>
    <div class="d-inline-flex gap-1 text-uppercase flex-wrap">
      <span class="badge small bg-secondary">{{getQuestionTypeLabel(data.type)}}</span>
      <span *ngIf="data.liveCoding" class="badge small bg-blue">Live Coding</span>
      <span class="badge small bg-primary">{{data.difficulty}}</span>
      <span *ngFor="let tech of data.technologies" class="badge small bg-info">{{tech}}</span>
    </div>
  </div>

  <div *ngIf="data.description" class="my-3 text-muted">
    {{data.description}}
  </div>
  
  <div *ngIf="data.text" class="my-3">
    {{data.text}}
  </div>

  <hr/>

  <form [formGroup]="previewForm" *ngIf="data.text && previewForm" (submit)="submitPreviewAnswer()" class="card">

    <div class="card-body">

      <div *ngIf="data.type !== 'FILL_IN_THE_BLANK'; else fitbQuestionText" class="my-3">
        <app-markdown-viewer [data]="data.fillInTheBlankText || ''"></app-markdown-viewer>
      </div>

      <ng-template #fitbQuestionText>
        <div class="my-3 text-muted">
          Fill in the blank(s).
        </div>
        <div class="my-2">
          <app-question-fill-in-the-blank-answer formControlName="fitbAnswer"
                                                 [showValidity]="submitted"
                                                 [correctIndexes]="correctIndexes"
                                                 [questionText]="data.fillInTheBlankText || ''"></app-question-fill-in-the-blank-answer>
        </div>
      </ng-template>

      <div *ngIf="data.selectAllThatApply" class="my-3 text-muted small">
        Select all that apply.
      </div>

      <div *ngIf="data.type === 'MULTIPLE_CHOICE' && data.choices" class="my-3">
        <app-question-choice-group formControlName="mcAnswer" [choices]="data.choices"></app-question-choice-group>
      </div>

      <div *ngIf="data.type === 'TRUE_FALSE'" class="my-3">
        <app-question-true-false-choice-group formControlName="tfAnswer"></app-question-true-false-choice-group>
      </div>

      <div *ngIf="data.type === 'SHORT_ANSWER' || data.type === 'INTERVIEW'" class="my-3">
        <div *ngIf="data.type === 'SHORT_ANSWER'" class="my-2">
          <div class="mb-2 text-muted small">
            Enter a short answer below:
          </div>
          <app-markdown-editor
            formControlName="saAnswer"
            label="Your answer here..."
            placeholder="Your answer here..."
            [showPreviewButton]="false"
            [useMarkdown]="false"></app-markdown-editor>
        </div>
        <div *ngIf="data.correctAnswer && (submitted || data.type === 'INTERVIEW')" class="w-100">
          <button class="btn btn-outline-primary w-100" type="button" (click)="correctAnswerCollapsed = !correctAnswerCollapsed" [attr.aria-expanded]="!correctAnswerCollapsed" aria-controls="revealAnswer">
            {{correctAnswerCollapsed ? 'Reveal' : 'Hide'}} correct answer <span class="bi bi-check2-circle"></span>
          </button>
          <div class="my-3" [(ngbCollapse)]="correctAnswerCollapsed">
            <div class="card card-body">
              <div class="card-text">
                <div class="mb-3">
                  <span class="bi bi-check2 text-success me-2"></span> <span class="fw-bold">Correct answer</span>
                </div>
                <div [innerHTML]="data.correctAnswer.correctAnswer | markdown"></div>
              </div>
              <div *ngIf="data.correctAnswer.explanation" class="alert alert-info my-3">
                <h6><span class="bi bi-info-circle-fill"></span> Explanation</h6>
                <p>
                  {{data.correctAnswer.explanation}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="submitted" class="my-3">
        <p class="fw-bold">
          <span *ngIf="checkAnswer(); else incorrect" class="text-success">Correct!</span>
          <ng-template #incorrect>
            <span class="text-danger">Incorrect!</span>
          </ng-template>
        </p>
      </div>

      <div *ngIf="data.type !== 'INTERVIEW'" class="d-flex flex-row gap-2 justify-content-end align-items-center">
        <button (click)="resetPreview()" type="reset" class="btn btn-outline-primary">
          Reset <span class="bi bi-arrow-counterclockwise"></span>
        </button>
        <button [disabled]="submitted || previewForm.invalid" type="submit" class="btn btn-primary">
          Submit Answer <span class="bi bi-arrow-right-circle"></span>
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #previewQuestionText>
  <div class="my-3">
    <span class="text-muted">Preview question here...</span>
  </div>
</ng-template>
