import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Quiz, QuizQuestion } from '@app/api/quiz/models/quiz.model';
import { CreateQuestionRequest } from '@app/api/question/models/create-question-request.model';
import { ActivatedRoute, Router } from '@angular/router';
import { QuizService } from '@app/api/quiz/services/quiz.service';
import { QuestionApiService } from '@app/api/question/services/question-api.service';
import { QuestionData } from '@app/api/question/models/question-data.model';
import { forkJoin, map, of } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewContainer, moveItemInArray } from '@angular/cdk/drag-drop';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { KeyBindingService } from '@app/core/services/key-binding.service';
import { QuestionResponse } from '@app/api/question/models/question-response.model';
import { mapToQuestionDifficulty, mapToQuestionType } from '@app/api/question/models/question-data-types';

@Component({
  selector: 'app-quiz-creator',
  templateUrl: './quiz-creator.component.html',
  styleUrls: ['./quiz-creator.component.sass'],
})
export class QuizCreatorComponent implements OnInit, OnDestroy {
  @ViewChild('addNewQuestionModal') addNewQuestionModal?: TemplateRef<any>;
  @ViewChild('addExistingQuestionsModal') addExistingQuestionsModal?: TemplateRef<any>;
  @ViewChild('quizPreview') quizPreview?: TemplateRef<any>;
  @ViewChild('questionPreview') questionPreview?: TemplateRef<any>;
  @Input() previewContainer: PreviewContainer = 'global';
  @Output() selectedQuestionsChanged = new EventEmitter<QuestionResponse[]>();

  quiz: Quiz = {
    description: '',
    questionCount: 0,
    questions: [],
    randomizeQuestions: false,
    technologies: [],
    title: '',
  };

  quizQuestions: QuizQuestion[] = [];
  selectedQuestions: QuestionResponse[] = [];
  currentQuestionIndex = 0;
  currentQuestion!: QuestionData;
  quizQuestionPreviews: QuestionData[] = [];
  loadingQuiz = false;
  quizForm: FormGroup;
  newQuestionForm: FormGroup;
  mode = 'create';
  saving = false;

  constructor(
    private route: ActivatedRoute,
    private quizService: QuizService,
    private questionService: QuestionApiService,
    private modalService: NgbModal,
    private localModalService: GlobalModalService,
    private router: Router,
    private keyBindingService: KeyBindingService,
    private questionApiService : QuestionApiService
  ) {
    this.quizForm = new FormGroup({
      id: new FormControl(),
      title: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9\s_-]+$/),
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      description: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      randomizeQuestions: new FormControl(false),
    });

    this.newQuestionForm = new FormGroup({
      questions: new FormArray([]),
    });

    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state?.['data']) {
      this.quiz = navigation.extras.state['data'];
      this.patchValues();
    }
  }

  patchValues() {
    this.quiz.questions.forEach((question) => {
      this.questionApiService.createQuestion(question.questionData).subscribe({
        next: (response) => {
          question.questionData.id = response.id;
        },
        error: (error) => {
          console.error('Error saving question', error);
        },
      });
    });

    if (this.quiz) {
      this.quizForm.patchValue({
        title: this.quiz.title,
        description: this.quiz.description,
        randomizeQuestions: this.quiz.randomizeQuestions,
      });
      this.quizQuestions = this.quiz.questions;
    }
  }
  ngOnInit() {
    // Check route data mode (create or edit)
    this.route.data.subscribe((data) => {
      const dataMode = data['mode'];

      if (dataMode === 'create') {
        this.quiz = {
          description: '',
          questionCount: 0,
          questions: [],
          randomizeQuestions: false,
          technologies: [],
          title: '',
        };
        this.mode = 'create';
      } else if (dataMode === 'edit') {
        this.mode = 'edit';
        const id = this.route.snapshot.params['id'];
        this.loadQuiz(id);
      }
    });

    this.keyBindingService.registerKeyBind('ctrl+s', (event) => {
      event.preventDefault();
      if (this.quizForm.invalid || !this.quizQuestions.length) {
        this.localModalService.alert({
          title: 'Error',
          content: 'Specify the Quiz title, description, as well as questions',
          type: 'info',
          okButtonText: 'Ok',
        });
      } else {
        this.onSubmitQuiz();
      }
    });
  }

  ngOnDestroy() {
    this.keyBindingService.deregisterKeyBind('ctrl+s');
  }

  toggleRandomize() {
    this.quiz.randomizeQuestions = !this.quiz.randomizeQuestions; 
  }

  toggleAlwaysInclude(index: number) {
    this.quizQuestions[index].alwaysInclude = !this.quizQuestions[index].alwaysInclude;
  }

  onSubmitQuiz() {
    this.quiz.title = this.quizForm.get('title')?.value;
    this.quiz.description = this.quizForm.get('description')?.value;
    this.quiz.randomizeQuestions = this.quizForm.get('randomizeQuestions')?.value;
    this.quiz.questions = this.quizQuestions;
    this.quiz.questionCount = this.quizQuestions.length;
    this.quiz.published = true;
    this.quizService.createQuiz(this.quiz).subscribe({
      next: (response) => {
        this.saving = true;
        this.localModalService.alert({
          title: 'Quiz created',
          content: 'Quiz created Successfully',
          type: 'success',
          okButtonText: 'Ok',
        });
        this.quizForm.reset();
        this.quizQuestions = [];
        const id = response.id;
        this.router
          .navigate(['course-composer', 'quiz-creator', 'edit-quiz', id])
          .then(() => {
            this.saving = false;
          });
      },
      error: (error) => {
        this.localModalService.alert({
          title: 'Error',
          content: error,
          type: 'danger',
          okButtonText: 'Ok',
        });
        console.log('Failed to create quiz :::', error);
      },
    });
  }

  updateQuiz() {
    this.quiz.title = this.quizForm.get('title')?.value;
    this.quiz.description = this.quizForm.get('description')?.value;
    this.quiz.randomizeQuestions = this.quizForm.get('randomizeQuestions')?.value;
    this.quiz.questions = this.quizQuestions.map(question => ({
      ...question,
      alwaysInclude: question.alwaysInclude, 
      
    }));
    this.quiz.questionCount = this.quizQuestions.length;
    const id = this.route.snapshot.params['id'];
    this.quizService.updateQuiz(id, this.quiz).subscribe({
      next: () => {
        this.saving = true;
        this.localModalService.alert({
          title: 'Quiz updated',
          content: 'Quiz updated Successfully',
          type: 'success',
          okButtonText: 'Ok',
        });
        this.quiz.questions.forEach(q => {
          console.log(`Question ID: ${q.id}, Always Include: ${q.alwaysInclude}`);
        });
        this.quizForm.reset();
        this.quizQuestions = [];
        this.loadQuiz(id);
        this.saving = false;
      },
    });
  }

  openDeleteQuizModal() {
    this.localModalService
      .confirm('Are you sure you want to delete this quiz?', {
        title: 'Delete Quiz',
        okButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'danger',
      })
      .subscribe({
        next: (result) => {
          if (result) {
            const id = this.route.snapshot.params['id'];
            this.quizService.deleteQuiz(id).subscribe({
              next: () => {
                console.log('Quiz deleted Successfully');
              },
              error: (e) => {
                console.log('Error while deleting quiz :', e);
              },
            });
          }
        },
      });
  }

  loadQuiz(id: number) {
    this.loadingQuiz = true;
    this.quizService.getQuizById(id).subscribe((quiz) => {
      this.quiz = quiz;
      this.loadingQuiz = false;
      this.quizForm.get('title')?.setValue(quiz.title);
      this.quizForm.get('description')?.setValue(quiz.description);
      this.quizForm.get('randomizeQuestions')?.setValue(quiz.randomizeQuestions);
      this.quizQuestions = quiz.questions.sort((a, b) => a.order - b.order);

      this.quizQuestions = this.quiz.questions.map((quizQuestion) => {
        return {
          questionData: quizQuestion.questionData,
          order: quizQuestion.order,
          alwaysInclude: quizQuestion.alwaysInclude || false,
        };
      });

      this.selectedQuestions = this.selectedQuestions.concat(
        this.quizQuestions.map((question) => {
          const questionResponse: QuestionResponse = {
            id: question.questionData.id,
            title: question.questionData.title,
            description: question.questionData.description || '',
            text: question.questionData.text || '',
            type: question.questionData.questionType || '',
            categories: [],
            tags: question.questionData.tags || [],
            technologies: question.questionData.technologies || [],
            choices: [],
            difficulty: question.questionData.difficulty,
            selectAllThatApply: false,
            smoothstackClient: '',
            published: question.questionData.published,
            deprecated: false,
            deprecationReason: '',
            questionType: {
              name: '',
              description: '',
              code: '',
            },
          };
          return questionResponse;
        })
      );
    });
  }

  openAddNewQuestionModal() {
    this.modalService.open(this.addNewQuestionModal, {
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  onAddQuestion(value: CreateQuestionRequest) {
    const quizQuestion: QuizQuestion = {
      questionData: value,
      order: this.quizQuestions.length,
      alwaysInclude: false,
    };
    this.quizQuestions = this.quizQuestions.concat(quizQuestion);
  }

  previewQuestion(index: number) {
    this.setCurrentQuestion(index);
    this.modalService.open(this.questionPreview, {
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  setCurrentQuestion(index: number) {
    if (index < 0 || index >= this.quizQuestions.length) {
      return;
    }

    this.currentQuestionIndex = index;
    const id = this.quizQuestions[index].questionData.id;

    if (id) {
      this.questionService.getQuestionDataForUpdate(id).subscribe({
        next: (response) => {
          this.currentQuestion = response;
        },
      });
    }
  }

  removeQuestion(index: number) {
    this.localModalService.confirm('Are you sure you want to remove this Question?', {
      title: 'Remove Question',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      type: 'danger',
    })
      .subscribe({
        next: (result) => {
          if (result) {
            const removedQuestion = this.quizQuestions.splice(index, 1)[0]; 
            this.updateQuestionOrder();
            this.selectedQuestions = this.selectedQuestions.filter(q => q.id !== removedQuestion.questionData.id);
            this.selectedQuestionsChanged.emit(this.selectedQuestions);
          }
        },
      });
  }
  
  populateQuestionDataForPreview() {
    const questionDataRequests = this.quizQuestions.map((quizQuestion, index) => {
      const id = this.quizQuestions[index].questionData.id;
      if (id) {
        return this.questionService.getQuestionDataForUpdate(id).pipe(
          map((response) => {
            this.quizQuestionPreviews[index] = response;
          })
        );
      }
      return of(null);
    });
  
    return forkJoin(questionDataRequests).toPromise();
  }
  
  openQuizPreviewModal() {
    this.quiz.title = this.quizForm.get('title')?.value;
    this.quiz.description = this.quizForm.get('description')?.value;
    this.quiz.questions = this.quizForm.get('questions')?.value;
    this.quizQuestionPreviews = [];
    this.populateQuestionDataForPreview().then(() => {
      this.modalService.open(this.quizPreview, {
        size: 'xl',
        scrollable: true,
        backdrop: 'static',
      });
    });
  }
  
  handleSelectedQuestions(questions: QuestionResponse[]) {
    this.selectedQuestions = questions;
  }

  openAddExistingQuestionsModal() {
    this.modalService.open(this.addExistingQuestionsModal, {
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  addExistingQuestion() {
    const startingOrder = this.quizQuestions.length; 

    const newQuestions = this.selectedQuestions.filter(selectedQuestion => 
      !this.quizQuestions.some(quizQuestion => quizQuestion.questionData.id === selectedQuestion.id)
    );
  
    this.quizQuestions = this.quizQuestions.concat(
      newQuestions.map((question, index) => {
        const quizQuestion: QuizQuestion = {
          questionData: {
            id: question.id,
            questionType: mapToQuestionType(question.type),
            title: question.title,
            choices:[],
            difficulty: mapToQuestionDifficulty(question.difficulty),
            published: question.published,
            text: question.text,
            description: question.description,
            tags: question.tags,
            technologies: question.technologies,
            selectAllThatApply: question.selectAllThatApply,
          } ,
          order: startingOrder + index, 
          alwaysInclude: false,
        };
        return quizQuestion;
        
      })
    );
  
  }

  onMoveItem(event: any): void {
    moveItemInArray(
      this.quizQuestions,
      event.previousIndex,
      event.currentIndex
    );
    this.quizQuestions[event.currentIndex].order = event.currentIndex;
    this.updateQuestionOrder();
  }

  updateQuestionOrder() {
    this.quizQuestions.forEach((question, index) => {
      question.order = index;
    });
  }

  mapQuestionDataToCreateQuestionRequest(
    questionData: QuestionData
  ): CreateQuestionRequest {
    return {
      ...questionData,
      difficulty: questionData.difficulty,
      published: questionData.published,
      questionType: questionData.type,
      text: questionData.text,
      title: questionData.title,
      description: questionData.description,
    } as CreateQuestionRequest;
  }

  addQuestion() {
    this.quiz.questions.push(<QuizQuestion>{
      order: this.quiz.questions.length,
      questionData: {},
      alwaysInclude: false,
    });

    this.setCurrentQuestion(this.quiz.questions.length - 1);
  }
}
