import { Injectable } from '@angular/core';
import { BuildInfo, environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ApiBuildInfoProperties {
  version: string;
  name: string;
  time: string;
  artifact: string;
  imageTag: string;
  commit: string;
  branch: string;
  buildNumber: string;
  environment: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor(private http: HttpClient) { }

  get version(): string {
    return '1.0.19';
  }

  get isProduction(): boolean {
    return environment.production;
  }

  get apiUrl(): string {
    return environment.apiUrl;
  }

  get buildInfo(): BuildInfo {
    return environment.buildInfo;
  }

  get apiVersion(): Observable<string> {
    return this.http.get(`${this.apiUrl}/version`, { responseType: 'text' });
  }

  get apiBuildInfo(): Observable<ApiBuildInfoProperties> {
    return this.http.get<ApiBuildInfoProperties>(`${this.apiUrl}/build-info.json`, { responseType: 'json' });
  }
}
