<div class="my-3 card">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <div class="me-2">
        <h5 class="card-title">Manage Learning Activities</h5>
        <p class="text-muted small">
          <span class="bi bi-info-circle me-1"></span>
          Use the filters to narrow down the list of learning activities.
        </p>
      </div>
      <button class="btn btn-outline-primary border-0" (click)="hideFilters = !hideFilters">
        <span class="visually-hidden">Toggle show filters</span>
        <span class="bi bi-chevron-down" *ngIf="hideFilters"></span>
        <span class="bi bi-chevron-up" *ngIf="!hideFilters"></span>
      </button>
    </div>
    <div [ngbCollapse]="hideFilters">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 col-md-3 my-2">
            <h6 class="h6">Cohort</h6>
            <div class="form-floating">
              <app-multi-select-field [options]="cohortOptions" [filterField]="true" [(ngModel)]="selectedCohortIds"
                                      (ngModelChange)="loadLearningActivities()"
                                      (searchChange)="onCohortSearchChange($event)"
                                      placeholder="Filter by cohorts"></app-multi-select-field>
            </div>
          </div>
          <div class="col-6 col-md-3 my-2">
            <h6 class="h6">Students</h6>
            <app-multi-select-field [options]="studentOptions" [filterField]="true" [(ngModel)]="selectedStudentIds"
                                    (ngModelChange)="loadLearningActivities()"
                                    (searchChange)="onStudentSearchChange($event)"
                                    placeholder="Filter by students"></app-multi-select-field>
          </div>
          <div class="col-4 col-md-2 my-2">
            <h6 class="h6">Course</h6>
            <app-multi-select-field [options]="courseOptions" [filterField]="true" [(ngModel)]="selectedCourseIds"
                                    (ngModelChange)="loadLearningActivities()"
                                    (searchChange)="onCourseSearchChange($event)"
                                    placeholder="Filter by course"></app-multi-select-field>
          </div>
          <div class="col-4 col-md-2 my-2">
            <h6 class="h6">Module</h6>
            <app-multi-select-field [options]="moduleOptions" [filterField]="true" [(ngModel)]="selectedModuleIds"
                                    (ngModelChange)="loadLearningActivities()"
                                    (searchChange)="onModuleSearchChange($event)"
                                    placeholder="Filter by module"></app-multi-select-field>
          </div>
          <div class="col-4 col-md-2 my-2">
            <h6 class="h6">Lesson</h6>
            <app-multi-select-field [options]="lessonOptions" [filterField]="true" [(ngModel)]="selectedLessonIds"
                                    (ngModelChange)="loadLearningActivities()"
                                    (searchChange)="onLessonSearchChange($event)"
                                    placeholder="Filter by lesson"></app-multi-select-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row my-3">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row flex-md-row-reverse">
          <div class="col-12 col-md-3 my-2">
            <app-search-bar-input
              [searchDelay]="500"
              (searchChange)="onActivitySearch($event)">
            </app-search-bar-input>
          </div>
          <div class="col-12 col-md-3 my-2">
            <app-multi-select-field
              [options]="activityTypeOptions"
              [(ngModel)]="selectedTypes"
              (ngModelChange)="loadLearningActivities()"
              placeholder="Filter by activity type">
            </app-multi-select-field>
          </div>
          <div class="col-12 col-md-6 my-2">
            <div class="d-flex align-items-center">
              <div class="col align-self-center">
                <button class="btn btn-danger" *ngIf="anyActivitySelected()" (click)="deleteSelectedActivities()">
                  <span class="bi bi-trash me-2"></span> Delete
                </button>
              </div>
              <div ngbDropdown class="mx-5">
                <button type="button"
                        ngbDropdownToggle
                        ngbTooltip="Sort"
                        class="btn btn-light">
                  <span class="bi bi-sort-alpha-down"></span>
                  Sort by: <span class="mx-1">{{getLabel()}}</span>
                </button>
                <div ngbDropdownMenu>
                  <button
                    (click)="onSort('id')"
                    ngbDropdownItem>
                    ID
                  </button>
                  <button
                    (click)="onSort( 'type')"
                    ngbDropdownItem>
                    Type
                  </button>
                  <button
                    (click)="onSort( 'progress')"
                    ngbDropdownItem>

                    Progress
                  </button>
                  <button
                    (click)="onSort( 'dueDate')"
                    ngbDropdownItem>
                    Due Date
                  </button>
                </div>
              </div>
              <div class="col align-self-center">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input type="radio"
                           [(ngModel)]="sortDirection"
                           name="sortDirection"
                           value="asc"
                           (ngModelChange)="onSortDirectionChange()"
                           class="form-check-input"/>
                    Ascending
                  </label>
                </div>
              </div>
              <div class="col align-self-center">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input type="radio"
                           [(ngModel)]="sortDirection"
                           name="sortDirection"
                           value="desc"
                            (ngModelChange)="onSortDirectionChange()"
                           class="form-check-input"/>
                    Descending
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row my-3">
  <div class="col-7">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">
          <input type="checkbox" (change)="selectAll($event)" [checked]="allSelected">
        </th>
        <th scope="col">Learning Activity</th>
        <th scope="col">Type
        </th>
        <th scope="col">Assigned to</th>
        <th scope="col">Due Date
        </th>
        <th scope="col">Progress
        </th>
        <th scope="col">Grade</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody *ngIf="!loading; else loadingRowsSkeleton">
      <tr *ngFor="let learningActivity of learningActivities">
        <td>
          <input type="checkbox" (change)="toggleSelection(learningActivity.id, $event)"
                 [checked]="isSelected(learningActivity.id)">
        </td>
        <td class="align-items-start text-start">
          <button
            *ngIf="learningActivity.type === 'LESSON' || learningActivity.type === 'ASSIGNMENT' || learningActivity.type === 'QUIZ'; else plainTitle"
            class="btn btn-link text-start" (click)="previewActivity(learningActivity)">
              <span [ngClass]="{ 'fw-bold': selectLearningActivity?.id === learningActivity.id }">
                {{ learningActivity.title }}
              </span>
          </button>
          <ng-template #plainTitle>
            <div class="px-3 py-2 d-flex align-items-center">
              {{ learningActivity.title }}
            </div>
          </ng-template>
        </td>
        <td>{{ learningActivity.type | titlecase }}</td>
        <td>{{ learningActivity.student.firstName }} {{ learningActivity.student.lastName }}</td>
        <td>{{ learningActivity.dueDate | date: 'MM/dd/yyyy' }}</td>
        <td>{{ learningActivity.progress | percent }}</td>
        <td>
            <span *ngIf="learningActivity.submission">
              <span *ngIf="learningActivity.submission.graded" class="fw-bold"
                    [ngClass]="{ 'text-success': learningActivity.submission.score >= 0.7, 'text-warning': learningActivity.submission.score < 0.7 && learningActivity.submission.score >= 0.6, 'text-danger': learningActivity.submission.score < 0.6 }">
                {{ (learningActivity.submission.score ? learningActivity.submission.score : 0) | percent }}
              </span>
              <span *ngIf="!learningActivity.submission.graded" class="text-muted">
                <span *ngIf="learningActivity.type === 'ASSIGNMENT' || learningActivity.type === 'QUIZ'">
                  Not Graded
                </span>
              </span>
            </span>
        </td>
        <td>
          <div ngbDropdown>
            <button class="btn btn-outline-primary dropdown-no-caret border-0" ngbDropdownToggle>
              <span class="bi bi-three-dots-vertical"></span>
            </button>
            <div ngbDropdownMenu>
              <button
                *ngIf="learningActivity.type === 'ASSIGNMENT' || learningActivity.type === 'LESSON' || learningActivity.type === 'QUIZ'"
                class="dropdown-item" (click)="previewActivity(learningActivity)">
                <span class="bi bi-eye me-2"></span>
                Preview
              </button>
              <button class="dropdown-item text-danger" (click)="showDeleteActivityModal(learningActivity)">
                <span class="bi bi-trash me-2"></span>
                Delete
              </button>
            </div>
          </div>
        </td>
      </tbody>
    </table>

    <div class="my-2 text-center">
      <ngb-pagination [(page)]="currentPage" [collectionSize]="totalItems" [maxSize]="10" [boundaryLinks]="true"
                      (pageChange)="loadLearningActivities()"></ngb-pagination>
    </div>
  </div>

  <div class="col-5">
    <div class="ms-lg-3"> 
      <div class="card overflow-y-scroll max-h-preview" *ngIf="selectLearningActivity; else noSelectedLearningActivity">
        <div class="card-body">
          <app-activity-view
            *ngIf="selectLearningActivity.type === 'ASSIGNMENT' || selectLearningActivity.type === 'QUIZ'"
            [activityId]="selectLearningActivity.id" [showNavigation]="false"></app-activity-view>
          <app-lesson-activity-view *ngIf="selectLearningActivity.type === 'LESSON'"
                                    [lessonActivityId]="selectLearningActivity.id"
                                    [showNavigation]="false"></app-lesson-activity-view>
        </div>
      </div>
      <ng-template #noSelectedLearningActivity>
        <div class="card max-h-preview">
          <div class="card-body text-muted">
            <h5 class="card-title">No Learning Activity Selected</h5>
            <p class="card-text">Select a learning activity from the table to preview it.</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #loadingRowsSkeleton>
  <tbody>
  <tr *ngFor="let _ of [].constructor(10)">
    <td class="animate__animated animate__slow animate__flash" colspan="7">
      <div class="py-2">
        &nbsp;
      </div>
    </td>
  </tr>
  </tbody>
</ng-template>
