<ng-container *ngIf="activity && hasNavigableActivities">
  <div class="w-100 d-flex justify-content-between align-items-center">
    <div>
      <button *ngIf="hasPreviousActivity"
              [routerLink]="learningRouteData.getRoutePathArray(previousActivity!.type, previousActivity!.id)"
              type="button"
              class="btn btn-outline-primary border-0 float-start">
        <div class="d-flex align-items-start">
          <span class="bi bi-chevron-left flex-shrink-1 me-2"></span>
          <div class="text-start">
            <div class="fw-bold small">
              {{ hasGoBackUp ? 'Back to ' : 'Previous ' }}
              {{ previousActivity?.type | titlecase }}</div>
            <div>{{ previousActivity?.title }}</div>
          </div>
        </div>
      </button>
    </div>
    <div ngbDropdown>
      <div class="input-group" *ngIf="activity.type === 'LESSON' && activity.linkedActivities && activity.linkedActivities.length; else standardNextButton">
        <button *ngIf="hasNextActivity"
                [routerLink]="learningRouteData.getRoutePathArray(nextActivity!.type, nextActivity!.id)"
                type="button"
                class="btn btn-outline-primary border-0">
          <div class="d-flex align-items-start">
            <div class="text-end">
              <div class="fw-bold small">
                {{ getNextButtonMessagePrefix() }}
                {{ nextActivity?.type | titlecase }}</div>
              <div>{{ nextActivity?.title }}</div>
            </div>
            <span class="bi bi-chevron-right flex-shrink-1 ms-2"></span>
          </div>
        </button>
        <button type="button"
                ngbDropdownToggle
                class="btn btn-outline-primary border-0 dropdown-no-caret">
        <span class="bi bi-three-dots-vertical">
          <span class="visually-hidden">View Assignments</span>
        </span>
        </button>
      </div>
      <div ngbDropdownMenu>
        <button *ngFor="let assignment of activity.linkedActivities" ngbDropdownItem [routerLink]="learningRouteData.getActivityPathArray(assignment.id)">
          <div class="py-3 d-flex align-items-center">
            <div class="me-3">
              <span class="bi bi-file-earmark-text fs-3 text-info"></span>
            </div>
            <div class="me-2">
              <div class="text-muted x-small">
                Assignment
              </div>
              {{assignment.order + 1}}. {{assignment.title}}
            </div>
          </div>
        </button>
      </div>
      <ng-template #standardNextButton>
        <button *ngIf="hasNextActivity"
                [routerLink]="learningRouteData.getRoutePathArray(nextActivity!.type, nextActivity!.id)"
                type="button"
                class="btn btn-outline-primary border-0 float-end">
          <div class="d-flex align-items-start">
            <div class="text-end">
              <div class="fw-bold small">
                {{ getNextButtonMessagePrefix() }}
                {{ nextActivity?.type | titlecase }}</div>
              <div>{{ nextActivity?.title }}</div>
            </div>
            <span class="bi bi-chevron-right flex-shrink-1 ms-2"></span>
          </div>
        </button>
      </ng-template>
    </div>
  </div>
</ng-container>
