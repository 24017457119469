<div *ngIf="!loading && assignment && assignmentActivity; else loader" class="container">
  <div *ngIf="assignmentActivity.parentModule && showNavigation" class="container">
    <div class="my-2">
      <button class="btn btn-outline-primary border-0" [routerLink]="learningRouteData.getModulePathArray()">
        <div class="d-flex align-items-start">
          <span class="bi bi-chevron-left me-2 flex-shrink-1"></span>
          <div class="text-start">
            <div class="small">
              <span class="fw-bold">Back to Module</span>
            </div>
            {{ assignmentActivity.parentModule.title }}
          </div>
        </div>
      </button>
    </div>
  </div>
  <app-assignment-view [gradingView]="!userIsStudent && !assignedToUser" [learningActivity]="assignmentActivity" [assignmentId]="assignment.id!"></app-assignment-view>
</div>

<ng-template #loader>
  <app-activity-skeleton-structure structureType="assignment"></app-activity-skeleton-structure>
</ng-template>
