<div class="container-fluid">
  <div class="border-bottom py-3 mb-3 ps-5">
    <h1 class="h3">
      <span class="bi bi-journal-bookmark-fill text-primary"></span>
      My Learning
    </h1>
  </div>
  <div class="container" *ngIf="learningActivities; else spinner">
    <div class="row">
      <div class="col-12 my-3">
        <div class="card card-body my-4" *ngIf="student; else spinner">
          <div class="my-2 d-flex justify-content-start align-items-center gap-3">
            <div style="width: 200px; height: 200px" class="d-flex justify-content-center align-items-center">
              <img *ngIf="profilePictureUrl; else spinner" [src]="profilePictureUrl"
                class="img-fluid img-thumbnail rounded-circle" alt="" />
            </div>
            <div class="d-flex flex-column">
              <div class="h2">{{student.firstName}} {{student.lastName}}</div>
              <div class="text-info">
                <span class="bi bi-envelope-at-fill"></span> {{student.email}}
              </div>
              <div class="d-flex flex-row flex-wrap gap-1 my-2"
                *ngIf="student.phases && student.phases.length; else noPhases">
                <span *ngFor="let phase of student.phases" class="badge bg-primary rounded-pill">{{phase}}</span>
              </div>
              <ng-template #noPhases>
                <div class="text-muted my-2 small fst-italic">
                  No phases assigned yet.
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col-12 my-3">
        <h4 class="h4">My Learning Activities</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12 my-3 align-items-stretch">
        <div class="card h-100">
          <div class="card-header bg-white">
            <div class="my-2 card-title">
              Courses
              <span *ngIf="allCourses.length <= 5" class="badge bg-info rounded-circle small">{{ allCourses.length }}</span>
              <span *ngIf="allCourses.length > 5" class="badge bg-info rounded-circle small">5+</span>
            </div>
          </div>
          <div class="card-body d-flex flex-column" *ngIf="courses.length; else noCourses">
            <ul class="list-group list-group-flush flex-grow-1">
              <li *ngFor="let course of courses" class="list-group-item">
                <div class="d-flex align-items-center">
                  <span class="bi bi-journal-bookmark-fill fs-4 text-primary me-2"></span>
                  <div class="flex-grow-1">
                    <div class="fw-bold">{{course.title}}</div>
                    <div class="text-muted small">Assigned {{course.assignedAt | date: 'MM/dd/yyyy'}}</div>
                  </div>
                  <div class="flex-shrink text-end">
                    <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(course)">
                      <span class="bi bi-arrow-right-short"></span>
                      <span class="visually-hidden">Go to course</span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div *ngIf="allCourses.length > 5" class="text-end mt-auto">
              <a (click)="loadCoursesPage()" class="link-primary cursor-pointer">View more <span class="bi bi-chevron-right"></span></a>
            </div>
          </div>
          <ng-template #noCourses>
            <div class="card-body">
              <div class="text-muted">
                No courses assigned yet.
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-lg-6 col-12 my-3 align-items-stretch">
        <div class="card h-100">
          <div class="card-header bg-white">
            <div class="my-2 card-title">
              Modules
              <span *ngIf="allModules.length <= 5"
                class="badge bg-info rounded-circle small">{{allModules.length}}</span>
              <span *ngIf="allModules.length > 5" class="badge bg-info rounded-circle small">5+</span>
            </div>
          </div>
          <div class="card-body d-flex flex-column" *ngIf="modules.length; else noModules">
            <ul class="list-group list-group-flush flex-grow-1">
              <li *ngFor="let module of modules" class="list-group-item">
                <div class="d-flex align-items-center">
                  <span class="bi bi-folder fs-4 text-primary me-2"></span>
                  <div class="fw-bold flex-grow-1">{{module.title}}</div>
                  <div class="flex-shrink text-end">
                    <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(module)">
                      <span class="bi bi-arrow-right-short"></span>
                      <span class="visually-hidden">Go to module</span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div *ngIf="allModules.length > 5" class="text-end mt-auto">
              <a (click)="loadModulesPage()" class="link-primary cursor-pointer">View more <span class="bi bi-chevron-right"></span></a>
            </div>
          </div>
          <ng-template #noModules>
            <div class="card-body">
              <div class="text-muted">
                No modules assigned yet.
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-12 my-3 align-items-stretch">
        <div class="card h-100">
          <div class="card-header bg-white">
            <div class="my-2 card-title">
              Lessons
              <span *ngIf="allLessons.length <= 5"
                class="badge bg-info rounded-circle small">{{allLessons.length}}</span>
              <span *ngIf="allLessons.length > 5" class="badge bg-info rounded-circle small">5+</span>
            </div>
          </div>
          <div class="card-body d-flex flex-column" *ngIf="lessons.length; else noLessons">
            <ul class="list-group list-group-flush flex-grow-1">
              <li *ngFor="let lesson of lessons" class="list-group-item">
                <div class="d-flex align-items-center">
                  <span class="bi bi-bookmark fs-4 text-primary me-2"></span>
                  <div class="fw-bold flex-grow-1">{{lesson.title}}</div>
                  <div class="flex-shrink text-end">
                    <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(lesson)">
                      <span class="bi bi-arrow-right-short"></span>
                      <span class="visually-hidden">Go to lesson</span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div *ngIf="allLessons.length > 5" class="text-end mt-auto">
              <a (click)="loadLessonsPage()" class="link-primary cursor-pointer">View more <span class="bi bi-chevron-right"></span></a>
            </div>
          </div>

          <ng-template #noLessons>
            <div class="card-body">
              <div class="text-muted">
                No lessons assigned yet.
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-lg-4 col-12 my-3 align-items-stretch">
        <div class="card h-100">
          <div class="card-header bg-white">
            <div class="my-2 card-title">
              Assignments
              <span *ngIf="allAssignments.length <= 5"
                class="badge bg-info rounded-circle small">{{allAssignments.length}}</span>
              <span *ngIf="allAssignments.length > 5" class="badge bg-info rounded-circle small">5+</span>
            </div>
          </div>
          <div class="card-body d-flex flex-column" *ngIf="assignments.length; else noAssignments">
            <ul class="list-group list-group-flush flex-grow-1">
              <li *ngFor="let assignment of assignments" class="list-group-item">
                <div class="d-flex align-items-center">
                  <span class="bi bi-file-earmark-text fs-4 text-primary me-2"></span>
                  <div class="fw-bold flex-grow-1">{{assignment.title}}</div>
                  <div class="me-2">
                    <app-percent-circle [percent]="assignment.progress" size="xxs"
                      [completed]="assignment.completed"></app-percent-circle>
                  </div>
                  <div class="flex-shrink text-end">
                    <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(assignment)">
                      <span class="bi bi-arrow-right-short"></span>
                      <span class="visually-hidden">Go to assignment</span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div *ngIf="allAssignments.length > 5" class="text-end mt-auto">
              <a (click)="loadAssignmentsPage()" class="link-primary cursor-pointer">View more <span class="bi bi-chevron-right"></span></a>
            </div>
          </div>
          <ng-template #noAssignments>
            <div class="card-body">
              <div class="text-muted">
                No assignments assigned yet.
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-lg-4 col-12 my-3 align-items-stretch">
        <div class="card h-100">
          <div class="card-header bg-white">
            <div class="my-2 card-title">
              Quizzes
              <span *ngIf="allQuizzes.length <= 5"
                class="badge bg-info rounded-circle small">{{allQuizzes.length}}</span>
              <span *ngIf="allQuizzes.length > 5" class="badge bg-info rounded-circle small">5+</span>
            </div>
          </div>
          <div class="card-body" *ngIf="quizzes.length; else noQuizzes">
            <ul class="list-group list-group-flush">
              <li *ngFor="let quiz of quizzes" class="list-group-item">
                <div class="d-flex align-items-center">
                  <span class="bi bi-check2-square fs-4 text-primary me-2"></span>
                  <div class="fw-bold flex-grow-1">{{quiz.title}}</div>
                  <div class="flex-shrink text-end">
                    <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(quiz)">
                      <span class="bi bi-arrow-right-short"></span>
                      <span class="visually-hidden">Go to quiz</span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div *ngIf="allQuizzes.length > 5" class="text-end mt-auto">
              <a (click)="loadQuizzesPage()" class="link-primary cursor-pointer">View more <span class="bi bi-chevron-right"></span></a>
            </div>
          </div>
          <ng-template #noQuizzes>
            <div class="card-body">
              <div class="text-muted">
                No quizzes assigned yet.
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col-12 my-3">
        <h4 class="h4">Graded Activities</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8 my-3">
        <div class="list-group mb-3">
          <div *ngFor="let activity of gradedActivities" class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <div class="w-100 py-2">
                <div class="d-flex justify-content-between w-100">
                  <div>
                    <div class="small fw-bold text-primary">
                      {{ activity.type | titlecase }}
                    </div>
                    <div>
                      {{ activity.title }}
                      <button type="button" role="link" class="btn btn-link" (click)="goToLearningActivity(activity)">
                        View
                      </button>
                    </div>
                  </div>
                  <app-percent-circle [percent]="activity.submission.score"
                    [color]="getColorFromScore(activity.submission.score)" size="xs"></app-percent-circle>
                </div>
                <div class="text-muted small d-flex justify-content-between w-100">
                  <span>
                    Graded by {{ activity.submission.gradedBy }}
                  </span>
                  <span>
                    {{ activity.submission.gradedAt | date: 'MM/dd/yyyy hh:mm:ss aa' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ngb-pagination *ngIf="gradedActivities.length else noGradedAcitivities" [collectionSize]="totalGradedActivities" [maxSize]="10" [boundaryLinks]="true" [(page)]="gradedPage" [pageSize]="10"></ngb-pagination>
        <ng-template #noGradedAcitivities>
            <div class="text-muted">
              No graded activities.
            </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <app-spinner></app-spinner>
</ng-template>

<ng-template #coursesPage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Courses <span class="badge bg-info rounded-circle small">{{allCourses.length}}</span></h4>
    <button type="button" class="btn-close" aria-label="Close"
      (click)="onDismissModal('Course');modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <ul *ngIf="!loading; else spinnerForTable" class="list-group list-group-flush flex-grow-1">
      <li *ngFor="let course of allCourses" class="list-group-item">
        <div class="d-flex align-items-center">
          <span class="bi bi-journal-bookmark-fill fs-4 text-primary me-2"></span>
          <div class="flex-grow-1">
            <div class="fw-bold">{{course.title}}</div>
            <div class="text-muted small">Assigned {{course.assignedAt | date: 'MM/dd/yyyy'}}</div>
          </div>
          <div class="flex-shrink text-end">
            <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(course);modal.dismiss()">
              <span class="bi bi-arrow-right-short"></span>
              <span class="visually-hidden">Go to course</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div class="my-2 d-flex justify-content-center align-items-center">
      <ngb-pagination [(page)]="currentPageOfCourses" [collectionSize]="totalCourses" [maxSize]="10"
        [boundaryLinks]="true" (pageChange)="loadCourses()"></ngb-pagination>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary"
      (click)="onDismissModal('Course');modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #modulesPage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Modules <span class="badge bg-info rounded-circle small">{{allModules.length}}</span></h4>
    <button type="button" class="btn-close" aria-label="Close"
      (click)="onDismissModal('Module');modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <ul *ngIf="!loading; else spinnerForTable" class="list-group list-group-flush flex-grow-1">
      <li *ngFor="let module of allModules" class="list-group-item">
        <div class="d-flex align-items-center">
          <span class="bi bi-folder fs-4 text-primary me-2"></span>
          <div class="fw-bold flex-grow-1">{{module.title}}</div>
          <div class="flex-shrink text-end">
            <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(module);modal.dismiss()">
              <span class="bi bi-arrow-right-short"></span>
              <span class="visually-hidden">Go to module</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div class="my-2 d-flex justify-content-center align-items-center">
      <ngb-pagination [(page)]="currentPageOfModules" [collectionSize]="totalModules" [maxSize]="10"
        [boundaryLinks]="true" (pageChange)="loadModules()"></ngb-pagination>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary"
      (click)="onDismissModal('Module');modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #lessonsPage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Lessons <span class="badge bg-info rounded-circle small">{{allLessons.length}}</span></h4>
    <button type="button" class="btn-close" aria-label="Close"
      (click)="onDismissModal('Lesson');modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <ul *ngIf="!loading; else spinnerForTable" class="list-group list-group-flush flex-grow-1">
      <li *ngFor="let lesson of allLessons" class="list-group-item">
        <div class="d-flex align-items-center">
          <span class="bi bi-file-earmark-text fs-4 text-primary me-2"></span>
          <div class="fw-bold flex-grow-1">{{lesson.title}}</div>
          <div class="me-2">
            <app-percent-circle [percent]="lesson.progress" size="xxs"
              [completed]="lesson.completed"></app-percent-circle>
          </div>
          <div class="flex-shrink text-end">
            <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(lesson);modal.dismiss()">
              <span class="bi bi-arrow-right-short"></span>
              <span class="visually-hidden">Go to assignment</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div class="my-2 d-flex justify-content-center align-items-center">
      <ngb-pagination [(page)]="currentPageOfLessons" [collectionSize]="totalLessons" [maxSize]="10"
        [boundaryLinks]="true" (pageChange)="loadLessons()"></ngb-pagination>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary"
      (click)="onDismissModal('Lesson');modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #assignmentsPage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Assignments <span
        class="badge bg-info rounded-circle small">{{allAssignments.length}}</span></h4>
    <button type="button" class="btn-close" aria-label="Close"
      (click)="onDismissModal('Assignment');modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <ul *ngIf="!loading; else spinnerForTable" class="list-group list-group-flush flex-grow-1">
      <li *ngFor="let assignment of allAssignments" class="list-group-item">
        <div class="d-flex align-items-center">
          <span class="bi bi-file-earmark-text fs-4 text-primary me-2"></span>
          <div class="fw-bold flex-grow-1">{{assignment.title}}</div>
          <div class="me-2">
            <app-percent-circle [percent]="assignment.progress" size="xxs"
              [completed]="assignment.completed"></app-percent-circle>
          </div>
          <div class="flex-shrink text-end">
            <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(assignment);modal.dismiss()">
              <span class="bi bi-arrow-right-short"></span>
              <span class="visually-hidden">Go to assignment</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div class="my-2 d-flex justify-content-center align-items-center">
      <ngb-pagination [(page)]="currentPageOfAssignments" [collectionSize]="totalAssignments" [maxSize]="10"
        [boundaryLinks]="true" (pageChange)="loadAssignments()"></ngb-pagination>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary"
      (click)="onDismissModal('Assignment');modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #quizzesPage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Quizes <span class="badge bg-info rounded-circle small">{{allQuizzes.length}}</span></h4>
    <button type="button" class="btn-close" aria-label="Close"
      (click)="onDismissModal('Quiz');modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <ul *ngIf="!loading; else spinnerForTable" class="list-group list-group-flush">
      <li *ngFor="let quiz of allQuizzes" class="list-group-item">
        <div class="d-flex align-items-center">
          <span class="bi bi-check2-square fs-4 text-primary me-2"></span>
          <div class="fw-bold flex-grow-1">{{quiz.title}}</div>
          <div class="flex-shrink text-end">
            <button class="btn btn-sm btn-outline-primary" (click)="goToLearningActivity(quiz);modal.dismiss()">
              <span class="bi bi-arrow-right-short"></span>
              <span class="visually-hidden">Go to quiz</span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <div class="my-2 d-flex justify-content-center align-items-center">
      <ngb-pagination [(page)]="currentPageOfQuizzes" [collectionSize]="totalQuizzes" [maxSize]="10"
        [boundaryLinks]="true" (pageChange)="loadQuizzes()"></ngb-pagination>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary"
      (click)="onDismissModal('Quiz');modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #spinnerForTable>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <app-spinner></app-spinner>
  </div>
</ng-template>
