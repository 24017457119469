import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LearningMaterialData } from '@app/api/models/learning-material-data.model';
import { User } from '@app/api/user/models/user.model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { CreateLearningActivityRequest, LearningActivity } from '@app/api/models/learning-activity.model';
import { GlobalModalService } from '@core/services/global-modal.service';
import { catchError, of } from 'rxjs';
import { Cohort } from '@app/api/models/cohort.model';
import { UserNotificationService } from '@app/api/notifications/services/user-notification.service';

@Component({
  selector: 'app-assign-learning-material-modal-button',
  templateUrl: './assign-learning-material-modal-button.component.html',
  styleUrls: ['./assign-learning-material-modal-button.component.sass']
})
export class AssignLearningMaterialModalButtonComponent {

  @Input() content: LearningMaterialData | null = null;
  @ContentChild(TemplateRef) template?: TemplateRef<any>;
  @ViewChild('assignModal') assignModal?: TemplateRef<any>;

  @Input() selectedUsers: User[] = [];
  @Input() selectedCohorts: Cohort[] = [];

  assigning = false;

  context: any = {
    openModal: () => this.openModal(),
  };

  constructor(
    private modal: NgbModal,
    private globalModal: GlobalModalService,
    private userNotificationService: UserNotificationService,
    private http: HttpClient,
    private api: ApiService) { }

  openModal() {
    this.modal.open(this.assignModal, { size: 'xl' });
  }

  closeModal() {
    this.selectedUsers = [];
    this.selectedCohorts = [];
    this.modal.dismissAll();
  }

  assign() {
    if (this.content) {
      this.assigning = true;
      this.assignMaterial({
        type: this.content.materialType!,
        materialId: this.content.id!,
        userIds: this.selectedUsers.map(u => u.id),
        cohortIds: this.selectedCohorts
          .filter(c => c.id)
          .map(c => c.id!),
        dueDate: new Date(),
      })
        .pipe(catchError(err => {
          console.error(err);
          this.assigning = false;
          return of({ error: err });
        }))
        .subscribe({
          next: (activities) => {
            if (!(activities instanceof Array)) {
              this.closeModal();
              this.globalModal.alert({
                type: 'danger',
                title: 'Error',
                content: activities.error.error.message || 'An error occurred while assigning the material.',
              });
              return;
            }
            this.globalModal.icon(
              `Successfully assigned the ${this.content?.materialType?.toLowerCase()}`,
              'bi-check-circle-fill text-success',
              {
                okButtonCallback: () => this.closeModal(),
              }
            );
            this.userNotificationService.updateNotifications();
            this.assigning = false;
          }
        });
    }
  }

  assignMaterial(request: CreateLearningActivityRequest) {
    return this.http.post<LearningActivity[]>(this.api.endpoint('/learning-activities'), request);
  }


}
