<div class="container" *ngIf="!loading && module && moduleActivity; else loader">

  <div *ngIf="moduleActivity && moduleActivity.parentCourse" class="container">
    <div class="my-2">
      <button class="btn btn-outline-primary border-0" [routerLink]="learningRouteData.getCoursePathArray()">
        <div class="d-flex align-items-start">
          <span class="bi bi-chevron-left me-2 flex-shrink-1"></span>
          <div class="text-start">
            <div class="small">
              <span class="fw-bold">Back to Course</span>
            </div>
            {{ moduleActivity.parentCourse.title }}
          </div>
        </div>
      </button>
    </div>
  </div>

  <div class="my-5 pt-3 flex-center flex-column">
    <div class="mb-3">
      <app-percent-circle [percent]="moduleActivity.progress"></app-percent-circle>
    </div>
    <div class="text-center">
      <div class="text-muted small">
        Module
      </div>
      <h3 class="text-center">
        {{ module.title }}
      </h3>
    </div>
    <div class="my-2 flex-center gap-1" *ngIf="module.technologies && module.technologies.length">
      <span *ngFor="let tag of module.technologies" class="badge rounded-pill small bg-info text-white">
        {{tag}}
      </span>
    </div>
    <p class="my-2 text-muted">
      {{ module.description }}
    </p>
  </div>

  <div class="row">
    <div class="col-12 col-md-8 offset-md-2">
      <div ngbAccordion class="list-group">
        <div *ngFor="let lesson of moduleActivity.linkedActivities" ngbAccordionItem>
          <button ngbAccordionHeader
                  class="list-group-item list-group-item-action cursor-pointer"
                  [routerLink]="!lesson.linkedActivities || !lesson.linkedActivities.length ? ['./lesson', lesson.id ] : undefined">
            <div ngbAccordionToggle class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <span class="bi bi-bookmark-fill text-success fs-2 mx-3"></span>
                <div class="my-3">
                  <div class="text-muted small">
                    Lesson
                  </div>
                  <a class="text-black fw-bold text-decoration-none" [routerLink]="['./lesson', lesson.id ]">
                    {{ lesson.order + 1 }}. {{ lesson.title }}
                  </a>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <div class="mx-2">
                  <app-percent-circle [percent]="lesson.progress" size="xxs"></app-percent-circle>
                </div>
                <button click-stop-propagation type="button" class="btn btn-link" [routerLink]="['./lesson', lesson.id ]">
                  <span class="bi bi-chevron-right text-primary mx-3 fs-5">
                    <span class="visually-hidden">Go to Lesson</span>
                  </span>
                </button>
              </div>
            </div>
          </button>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody class="p-0 m-0">
              <ng-template>
                <div class="list-group list-group-flush p-0 m-0">
                  <button *ngFor="let assignment of lesson.linkedActivities"
                          [routerLink]="['./lesson', lesson.id, 'activity', assignment.id ]"
                          class="list-group-item list-group-item-action">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <span class="bi bi-file-earmark-text-fill text-info fs-2 mx-3"></span>
                        <div class="my-3">
                          <div class="text-muted small">
                            Assignment
                          </div>
                          <div class="fw-bold">
                            {{ assignment.order + 1 }}. {{ assignment.title }}
                          </div>
                        </div>
                      </div>

                      <div class="d-flex align-items-center">
                        <div class="mx-2">
                          <app-percent-circle [percent]="assignment.progress" size="xxs"></app-percent-circle>
                        </div>
                        <span class="bi bi-chevron-right text-primary mx-3 fs-5">
                        <span class="visually-hidden">Go to Assignment</span>
                      </span>
                      </div>
                    </div>
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <app-activity-skeleton-structure></app-activity-skeleton-structure>
</ng-template>
