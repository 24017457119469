import { Component, Input, ViewChild } from '@angular/core';
import { AttachmentData, LessonAssignmentData, LessonData } from '@app/api/models/learning-material-data.model';
import { ApiService } from '@core/services/api.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lesson-preview',
  templateUrl: './lesson-preview.component.html',
  styleUrls: ['./lesson-preview.component.sass']
})
export class LessonPreviewComponent {

  @ViewChild('previewAssignmentModal') previewAssignmentModal!: NgbModal;

  @Input() lessonData?: LessonData;
  loadingAssignmentPreview = false;
  previewAssignmentData?: AssignmentData;

  constructor(
    private apiService: ApiService,
    private assignmentApi: AssignmentApiService,
    private modalService: NgbModal) { }

  get presentationAttachment(): AttachmentData | undefined {
    return this.lessonData?.lessonAttachments
      .find(attachment => attachment.attachmentType === 'PRESENTATION');
  }

  get presentationFileUrl(): string {
    const fileRecordId = this.presentationAttachment?.fileRecordId;
    return fileRecordId ? this.apiService.endpoint(['file-records', fileRecordId, 'public-view']) : '';
  }

  get hasAssignments(): boolean {
    return this.lessonData?.assignments.length !== 0;
  }

  openAssignmentPreview(assignment: LessonAssignmentData): void {
    this.loadingAssignmentPreview = true;
    this.assignmentApi.getAssignment(assignment.id).subscribe({
      next: (assignmentData) => {
        this.previewAssignmentData = assignmentData;
        this.loadingAssignmentPreview = false;

        this.modalService.open(this.previewAssignmentModal, {
          size: 'xl',
          scrollable: true
        });
      },
      error: () => {
        this.loadingAssignmentPreview = false;
      }
    });
  }

}
