import { Component, OnDestroy, OnInit } from '@angular/core';
import { LessonData, ModuleData } from '@app/api/models/learning-material-data.model';
import { ModuleApiService } from '@app/api/module/services/module-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalToastService } from '@core/services/global-toast.service';
import { catchError, of } from 'rxjs';
import { KeyBindingService } from '@core/services/key-binding.service';
import { GlobalModalService } from '@core/services/global-modal.service';

@Component({
  selector: 'app-module-form-page',
  templateUrl: './module-form-page.component.html',
  styleUrls: ['./module-form-page.component.sass']
})
export class ModuleFormPageComponent implements OnInit,OnDestroy {

  moduleData: ModuleData = {
    title: '',
    description: '',
    lessons: [],
    technologies: [],
    order: 0
  };
  saved = false;
  showAddToCourseButton: boolean = false;
  courseId: number = -1;
  mode: 'create' | 'edit' = 'create';

  constructor(
    private moduleApi: ModuleApiService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: GlobalModalService,
    private keyBindingService: KeyBindingService,
    private toastService: GlobalToastService) {

    this.mode = this.route.snapshot.data['mode'];

    if (this.mode === 'edit') {
      const id = this.route.snapshot.params['id'];
      this.moduleApi.getModuleById(id).subscribe(moduleData => {
        this.moduleData = moduleData;
      });
    }

    this.keyBindingService.registerKeyBind('ctrl+s', (event) => {
      event.preventDefault();
      if (!this.cannotSave) {
        this.saveModule();
      }
    });

  }

  ngOnInit(){
    this.route.queryParams.subscribe((params) => {
      if (params['courseId']) {
        this.showAddToCourseButton = true;
        this.courseId = +params['courseId'];
      }
    });
  }

  get saveModuleObservable() {

    const moduleId = this.moduleData.id;

    if (moduleId) {
      return this.moduleApi.updateModule(moduleId, this.moduleData)
        .pipe(catchError(err => {
          this.toastService.show({
            content: 'Unable to save module',
            type: 'danger'
          });
          if (err.status && err.status === 400) {
            this.modalService.alert({
              title: 'Error',
              type: 'danger',
              content: `There was an error creating the module.\n\n${err.error.message}`
            });
          }
          console.error(err);
          return of(null);
        }));
    } else {

      return this.moduleApi.createModule(this.moduleData)
        .pipe(catchError(err => {
          this.toastService.show({
            content: 'Unable to create module',
            type: 'danger'
          });
          if (err.status && err.status === 400) {
            this.modalService.alert({
              title: 'Error',
              type: 'danger',
              content: `There was an error creating the module.\n\n${err.error.message}`
            });
          }
          console.error(err);
          return of(null);
        }));
    }
  }

  saveModule(publish?: boolean, navigate? : boolean): void {

    if (publish !== undefined) {
      this.moduleData.published = publish;
    }

    const moduleId = this.moduleData.id;

    const saveModule = this.saveModuleObservable;

    if (moduleId) {
      saveModule.subscribe({
        next: (moduleData) => {
          if (moduleData) {
            this.moduleData = moduleData;
            this.saved = true;
            this.toastService.show({
              content: 'Module saved successfully!',
              type: 'success'
            });
          }
          if(navigate){
            this.router.navigate(['/course-composer/lessons/create'], {
              queryParams: { moduleId: moduleData?.id },
            });
          }
        }
      });
    } else {
      saveModule.subscribe({
        next: (moduleData) => {
          if(navigate){
            this.router.navigate(['/course-composer/lessons/create'], {
              queryParams: { moduleId: moduleData?.id },
            });
          }
          else{
            if (moduleData) {
              if(this.showAddToCourseButton){
                this.router.navigate(
                  [`/course-composer/courses/edit`, this.courseId],
                  { queryParams: { moduleId: moduleData.id } }
                );
              }
              else{
                this.saved = true;
                this.router.navigate(['course-composer', 'modules', 'edit', moduleData.id])
                .then(() => {
                  this.toastService.show({
                    content: 'Module created successfully!',
                    type: 'success'
                  });
                  this.moduleData = moduleData;
                  });
              }
            }
          }
        }
      });
    }

  }

  get cannotSave() {
    // Module has at least 1 lesson
    return this.moduleData.lessons.length === 0;
  }

  get cannotPublish() {
    // Module has to be able to save and title and description must be filled out
    return this.cannotSave || !this.moduleData.title || !this.moduleData.description;
  }

  ngOnDestroy(): void {
    this.keyBindingService.deregisterKeyBind('ctrl+s');
  }

  openEditLessonPage(lessonData: LessonData) {
    this.modalService.confirm('You have unsaved changes. Would you like to save this module before leaving?', {
      title: 'Save Module',
      okButtonText: 'Save',
      cancelButtonText: 'Don\'t Save',
      type: 'warning',
      dismissable: true,
      okButtonCallback: (modal) => {
        this.saveModuleObservable.subscribe({
          next: (moduleData) => {
            if (moduleData) {
              this.moduleData = moduleData;
              this.saved = true;
              this.router.navigate(['course-composer', 'lessons', 'edit', lessonData.id]);
              modal?.closeModal();
            }
          }
        });
      },
      cancelButtonCallback: (modal) => {
        this.router.navigate(['course-composer', 'lessons', 'edit', lessonData.id]);
        modal?.closeModal();
      }
    });
  }

  triggerSaveModule(){
    this.saveModule(false,true);
  }

}
