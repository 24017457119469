import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { ModuleApiService } from '../../services/module-api.service';
import { ModuleData } from '@app/api/models/learning-material-data.model';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-view-edit-modules',
  templateUrl: './view-edit-modules.component.html',
  styleUrls: ['./view-edit-modules.component.sass']
})
export class ViewEditModulesComponent implements OnInit {

  @Input() showTitle = true;
  @Input() currentModuleId?: number;
  modules: ModuleData [] = [];
  loading = false;

  constructor(private moduleApi: ModuleApiService,
    private router: Router,
    private modal: GlobalModalService){
  }

  ngOnInit() {
    this.loading = true;
    this.moduleApi.getModulesByOwner('self').subscribe(page => {
      this.modules = page.content.filter(m => m.id !== this.currentModuleId);
      this.loading = false;
    });
  }

  truncateText(title: string, limit = 10) {
    if (title.length > limit) {
      return title.substring(0, limit) + '...';
    }
    return title;
  }

  openEditModulePage(module: ModuleData) {
    this.router.navigate(['course-composer', 'modules', 'edit', module.id]);
  }

  deleteModule(id: number) {
    this.modal.confirm('Are you sure you want to delete this Module?', {
      title: 'Delete Module',
      type: 'danger',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).subscribe({
      next: (result) => {
        if (result) {
          this.moduleApi.deleteModule(id)
            .subscribe({
              next: () => {
                this.modules = this.modules.filter(module => module.id !== id);
              },
              error: (err) => {
                if (err.status === 400) {
                  this.modal.alert({
                    type: 'danger',
                    title: 'Unable to Delete Lesson',
                    content: err.error.message,
                    okButtonText: 'Ok'
                  });
                }
              }
            });
        }
      }
    });
  }

}
