import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ContentLibraryService, ContentQueryParams } from '@app/api/content-library/services/content-library.service';
import {
  ActivityMaterialType,
  ActivityMaterialTypes
} from '@app/api/categorization/models/activity-material-type.model';
import { CourseData, LearningMaterialData, LessonData, ModuleData } from '@app/api/models/learning-material-data.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionApiService } from '@app/api/question/services/question-api.service';
import { AssignmentApiService } from '@app/api/assignment/services/assignment-api.service';
import { AssignmentData } from '@app/api/assignment/models/assignment-data.model';
import { QuestionData } from '@app/api/question/models/question-data.model';
import { TitleCasePipe } from '@angular/common';
import { LessonApiService } from '@app/api/lesson/services/lesson-api.service';
import { MultiSelectOption } from '@core/components/form-controls/multi-select-field/multi-select-field.component';
import { GlobalModalService } from '@core/services/global-modal.service';
import { ModuleApiService } from '@app/api/module/services/module-api.service';
import { CourseApiService } from '@app/api/course/services/course-api.service';
import { catchError, of } from 'rxjs';

export type ContentLibraryColumn = 'TYPE' | 'TITLE' | 'DESCRIPTION' | 'AUTHOR' | 'PUBLISHED' | 'CREATED' | 'UPDATED' | 'ACTION' | 'DETAILS';

export interface ContentLibraryFilter {
  technologies: string[];
  published: boolean;
  unpublished: boolean;
  deprecated: boolean;
  notDeprecated: boolean;
}

@Component({
  selector: 'app-content-library',
  templateUrl: './content-library.component.html',
  styleUrls: ['./content-library.component.sass']
})
export class ContentLibraryComponent implements OnInit {

  @ViewChild('previewModal') previewModal?: TemplateRef<any>;
  @ViewChild('filterModal') filterModal?: TemplateRef<any>;
  @Input() contentTypes: ActivityMaterialType[] = [];
  @Input() mode: 'table' | 'grid' = 'table';
  @Input() includeColumns: ContentLibraryColumn[] = ['TYPE', 'TITLE', 'DESCRIPTION', 'AUTHOR', 'PUBLISHED', 'CREATED', 'UPDATED', 'ACTION', 'DETAILS'];

  startingContentTypes: ActivityMaterialType[] = [];

  @Input() initialFilter: Partial<ContentLibraryFilter> = {
  };

  filter: ContentLibraryFilter = {
    technologies: [] as string[],
    published: true,
    unpublished: true,
    deprecated: true,
    notDeprecated: true,
    ...this.initialFilter
  };

  previewData: {
    materialData: LearningMaterialData | null,
    assignment: AssignmentData | null
    question: QuestionData | null,
    lesson: LessonData | null,
    module: ModuleData | null,
    course: CourseData | null
  } = {
      materialData: null,
      assignment: null,
      question: null,
      lesson: null,
      module: null,
      course: null
    };
  loadingPreview = false;

  pagination = {
    search: '',
    page: 1,
    size: 10,
    currentPageSize: 0,
    sortBy: 'title',
    direction: 'asc',
    total: 0
  };
  contentData: LearningMaterialData[] = [];
  contentLoading = false;
  searchQuery = '';

  constructor(
    private contentLibrary: ContentLibraryService,
    private modal: NgbModal,
    private router: Router,
    private modalService: GlobalModalService,
    private assignmentApi: AssignmentApiService,
    private questionApi: QuestionApiService,
    private lessonApi: LessonApiService,
    private moduleApi: ModuleApiService,
    private courseApi: CourseApiService,
    private route: ActivatedRoute
  ) { }

  showColumn(column: ContentLibraryColumn): boolean {
    return this.includeColumns.includes(column);
  }

  ngOnInit(): void {
    this.startingContentTypes = [...this.contentTypes];
    this.route.queryParams.subscribe(params => {
      this.pagination.search = params['search'] || '';
      this.searchQuery = this.pagination.search;
      this.loadContent();
    });
  }

  resetFilter() {
    this.filter = {
      technologies: [] as string[],
      published: true,
      unpublished: true,
      deprecated: true,
      notDeprecated: true,
    };
    this.contentTypes = [...this.startingContentTypes];
  }

  get hasFilter(): boolean {
    return this.filter.technologies.length > 0 ||
      !this.filter.published ||
      !this.filter.unpublished ||
      !this.filter.deprecated ||
      !this.filter.notDeprecated ||
      !(this.contentTypes.length === this.startingContentTypes.length
        && this.contentTypes.every((v, i) => v === this.startingContentTypes[i]));
  }


  loadContent() {
    this.contentLoading = true;
    const params: ContentQueryParams = {
      types: this.contentTypes,
      sort: this.pagination.sortBy + ',' + this.pagination.direction,
      search: this.pagination.search,
      page: this.pagination.page - 1,
      size: this.pagination.size,
      technologies: this.filter.technologies
    };

    if (this.searchQuery.trim()) {
      params.search = this.searchQuery;
    } else {
      params.search = '';
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sort: this.pagination.sortBy + ',' + this.pagination.direction,
        search: this.pagination.search || null,
        page: this.pagination.page,
        size: this.pagination.size,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    if (this.filter.published && !this.filter.unpublished) {
      params.published = true;
    } else if (!this.filter.published && this.filter.unpublished) {
      params.published = false;
    }

    if (this.filter.deprecated && !this.filter.notDeprecated) {
      params.deprecated = true;
    } else if (!this.filter.deprecated && this.filter.notDeprecated) {
      params.deprecated = false;
    }

    this.contentLibrary.getContent(params).subscribe((page) => {
      this.contentData = page.content
        .map((content) => {
          if (!content.title) {
            const titlecase = new TitleCasePipe().transform;
            content.title = 'Untitled ' + titlecase(content.materialType);
          }

          return content;
        });
      this.pagination.total = page.totalElements;
      this.contentLoading = false;
    });
  }

  doAction(action: 'preview' | 'assign' | 'edit' | 'delete', content: LearningMaterialData) {
    switch (action) {
      case 'preview':
        this.openPreviewModal(content);
        break;
      case 'edit':
        if (content.materialType === 'ASSIGNMENT') {
          this.router.navigate(['assignment-composer', content.id]);
        } else if (content.materialType === 'QUESTION') {
          this.router.navigate(['course-composer', 'question-creator', 'edit', content.id]);
        } else if (content.materialType === 'LESSON') {
          this.router.navigate(['course-composer', 'lessons', 'edit', content.id]);
        } else if (content.materialType === 'MODULE') {
          this.router.navigate(['course-composer', 'modules', 'edit', content.id]);
        } else if (content.materialType === 'COURSE') {
          this.router.navigate(['course-composer', 'courses', 'edit', content.id]);
        }
        break;
      case 'delete':
        this.deleteContent(content);
        break;
    }
  }

  openPreviewModal(content: LearningMaterialData) {
    if (!this.previewModal && !content.id) {
      return;
    }

    const { materialType } = content;
    this.loadingPreview = true;
    this.previewData.materialData = content;
    this.modal.open(this.previewModal, {
      size: 'xl',
      scrollable: true
    });

    if (materialType === 'ASSIGNMENT') {
      this.assignmentApi.getAssignment(content.id!).subscribe({
        next: (assignment) => {
          this.previewData.assignment = assignment;
          this.loadingPreview = false;
        }
      });
    } else if (materialType === 'QUESTION') {
      this.questionApi.getQuestionDataForUpdate(content.id!).subscribe({
        next: (question) => {
          this.previewData.question = question;
          this.loadingPreview = false;
        }
      });
    } else if (materialType === 'LESSON') {
      this.lessonApi.getLesson(content.id!).subscribe({
        next: (lesson) => {
          this.previewData.lesson = lesson;
          this.loadingPreview = false;
        }
      });
    } else if (materialType === 'MODULE') {
      this.moduleApi.getModuleById(content.id!).subscribe({
        next: (module) => {
          this.previewData.module = module;
          this.loadingPreview = false;
        }
      });
    } else if (materialType === 'COURSE') {
      this.courseApi.getCourseById(content.id!).subscribe({
        next: (course) => {
          this.previewData.course = course;
          this.loadingPreview = false;
        }
      });
    }
  }

  closePreviewModal() {
    this.modal.dismissAll();
    this.previewData.materialData = null;
    this.previewData.assignment = null;
    this.previewData.question = null;
    this.previewData.lesson = null;
    this.previewData.module = null;
    this.previewData.course = null;
  }

  openFilterModal() {
    if (!this.filterModal) {
      return;
    }

    this.modal.open(this.filterModal);
  }

  get typesOptions(): MultiSelectOption[] {
    return ActivityMaterialTypes.values().map((type) => {
      return {
        value: type,
        label: new TitleCasePipe().transform(type)
      };
    });
  }

  deleteContent(content: LearningMaterialData) {
    const { materialType } = content;
    const materialTypeString = new TitleCasePipe().transform(materialType);
    this.modalService.confirm(`Are you sure you want to delete this ${materialTypeString}?`, {
      title: 'Delete Content',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      type: 'danger'
    }).subscribe({
      next: (result) => {
        if (result) {
          if (materialType === 'COURSE') {
            this.courseApi.deleteCourse(content.id!)
              .pipe(catchError((err) => {
                if (err.status === 400) {
                  this.modalService.alert({
                    type: 'danger',
                    title: 'Unable to Delete Course',
                    content: err.error.message,
                    okButtonText: 'Ok'
                  });
                }

                return of(err);
              }))
              .subscribe({
                next: () => {
                  this.loadContent();
                }
              });
          } else if (materialType === 'MODULE') {
            this.moduleApi.deleteModule(content.id!)
              .pipe(catchError((err) => {
                if (err.status === 400) {
                  this.modalService.alert({
                    type: 'danger',
                    title: 'Unable to Delete Module',
                    content: err.error.message,
                    okButtonText: 'Ok'
                  });
                }

                return of(err);
              }))
              .subscribe({
                next: () => {
                  this.loadContent();
                }
              });
          } else if (materialType === 'ASSIGNMENT') {
            this.assignmentApi.deleteAssignment(content.id!).subscribe({
              next: () => {
                this.loadContent();
              }
            });
          } else if (materialType === 'QUESTION') {
            this.questionApi.deleteQuestion(content.id!).subscribe({
              next: () => {
                this.loadContent();
              }
            });
          } else if (materialType === 'LESSON') {
            this.lessonApi.deleteLesson(content.id!)
              .pipe(catchError((err) => {
                if (err.status === 400) {
                  this.modalService.alert({
                    type: 'danger',
                    title: 'Unable to Delete Lesson',
                    content: err.error.message,
                    okButtonText: 'Ok'
                  });
                }

                return of(err);
              }))
              .subscribe({
                next: () => {
                  this.loadContent();
                }
              });
          }
        }
      }
    });
  }
}
