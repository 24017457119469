<div class="list-group list-group-flush" *ngIf="moduleData; else spinner">

  <ng-container *ngIf="moduleData.title">
    <button type="button"
            (click)="lessonsCollapse = !lessonsCollapse"
            class="list-group-item list-group-item-action">
      <div class="d-flex justify-content-between" [class.ps-3]="indent">
        <div class="me-2">
          <div class="d-flex">
            <span class="bi bi-folder-fill text-info me-1"></span>
            <div>
              <div class="fw-bold">{{moduleData.title}}</div>
              <div *ngIf="moduleData.technologies && moduleData.technologies.length" class="mb-1">
                <span class="badge bg-info rounded-pill small me-1" *ngFor="let techTag of moduleData.technologies">
                  {{techTag}}
                </span>
              </div>
              <div class="d-none d-md-block text-muted small text-wrap">
                {{moduleData.description}}
              </div>
            </div>
          </div>

        </div>
        <span *ngIf="moduleData.lessons && moduleData.lessons.length" class="bi bi-chevron-down align-self-center"></span>
      </div>
    </button>

    <div class="list-group-item p-0 m-0 list-group-item-light">
      <div class="list-group list-group-flush" [ngbCollapse]="lessonsCollapse">
        <li *ngFor="let lesson of moduleData.lessons" class="list-group-item py-3 pe-0">
          <div class="d-flex justify-content-between ps-3" [class.ps-5]="indent">
            <div class="me-2">
              <div class="d-flex">
                <span class="bi bi-bookmark-fill text-success me-1"></span>
                <div>
                  <div class="fw-bold">{{lesson.title}}</div>
                  <div *ngIf="lesson.technologies && lesson.technologies.length" class="mb-1">
                    <span class="badge bg-info rounded-pill small me-1" *ngFor="let techTag of lesson.technologies">
                      {{techTag}}
                    </span>
                  </div>
                  <div class="d-none d-md-block text-muted small text-wrap">
                    {{lesson.description | truncated: 50}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </div>
    </div>
  </ng-container>

</div>

<ng-template #spinner>
  <div class="text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
