<div class="row" *ngIf="!isLoading; else loadingTem">
  <div class="m-2 col-12 col-md-7 col-lg-5">
    <form
      [formGroup]="form">
      <div class="my-3">
        <app-text-field
          controlName="title"
          label="Title">
        </app-text-field>
      </div>
      <div class="my-3">
        <app-markdown-editor
          formControlName="description"
          [useMarkdown]="false"
          placeholder="Description"
          [showPreviewButton]="false">
        </app-markdown-editor>
        <app-control-validation-message
          [control]="form.controls.description"></app-control-validation-message>
      </div>

      <div class="my-3">
        <app-text-field controlName="tags" label="Tags"></app-text-field>
        <!--suppress XmlInvalidId -->
        <label for="tags" class="ms-1 text-muted small">
          Separate tags by commas. (e.g training,internal,java)
        </label>
      </div>

      <div class="my-3">
        <app-file-upload-field
          controlName="thumbnailFile"
          [previewImage]="true"
          [imagePreviewUrl]="thumbnailUrl"
          restrictAspectRatio="16x9"
          acceptedExtensions=".jpg,.jpeg,.png,.gif"
          label="Drag and drop thumbnail here">
        </app-file-upload-field>
      </div>

    </form>
  </div>
  <div class="m-2 col-12 col-md-4 col-lg-3 align-self-end">
    <button
      *ngIf=" !isSubmitting; else loadingButton"
      type="button"
      class="btn btn-primary"
      (click)="submitForm()"
      [disabled]="form.invalid">
      Update
    </button>
  </div>
</div>

<ng-template #loadingButton>
  <button class="btn btn-primary" disabled>
    Updating...
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button>
</ng-template>

<ng-template #loadingTem>
  <div class="vh-100  d-flex flex-center">
    <app-spinner [centered]="true"/>
  </div>
</ng-template>

<ng-template #videoUpdateSuccess>
  <div class="modal-body">
    <div class="my-5">
      <div class="w-100 d-flex justify-content-center align-items-center flex-column">
        <span class="bi bi-check-circle-fill text-success fs-1 mb-3"></span>
        <span class="fs-4">Video update successfully!</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="navigateBacK()">Close</button>
    <button type="button" class="btn btn-primary" (click)="watchNow()">Watch it now</button>
  </div>
</ng-template>

