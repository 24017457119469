import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import {
  AssignmentSubmissionPart,
  CommentReply,
  CreateLearningActivityRequest,
  LearningActivity,
  LearningActivityTree,
  SubmissionComment,
  UpdateLearningActivityRequest
} from '@app/api/models/learning-activity.model';
import { LearningMaterialData } from '@app/api/models/learning-material-data.model';
import { Page, PageParams } from '@app/api/models/page.model';
import { LearningActivityQuery } from '@app/api/learning/models/learning-activity-query.model';

@Injectable({
  providedIn: 'root'
})
export class LearningActivityService {

  constructor(private http: HttpClient, private api: ApiService) { }

  getLearningActivitiesForStudent(studentId: number, query?: LearningActivityQuery & PageParams): Observable<Page<LearningActivity>> {
    const params = query ? {
      ...query
    } : {};
    return this.http.get<Page<LearningActivity>>(this.api.endpoint(['students', studentId, 'learning-activities']), {
      params
    });
  }

  getLearningActivity(learningActivityId: number): Observable<LearningActivity> {
    return this.http.get<LearningActivity>(this.api.endpoint(['learning-activities', learningActivityId]));
  }

  completeLearningActivity(learningActivityId: number): Observable<LearningActivity> {
    return this.http.put<LearningActivity>(this.api.endpoint(['learning-activities', learningActivityId, 'complete']), {});
  }

  updateLearningActivity(id: number, request: UpdateLearningActivityRequest): Observable<LearningActivity> {
    return this.http.put<LearningActivity>(this.api.endpoint(['learning-activities', id]), request);
  }

  deleteLearningActivity(id: number): Observable<void> {
    return this.http.delete<void>(this.api.endpoint(['learning-activities', id]));
  }

  saveSubmissionPart(learningActivityId: number, submissionPart: AssignmentSubmissionPart): Observable<LearningActivity> {
    return this.http.post<LearningActivity>(this.api.endpoint(['learning-activities', learningActivityId, 'submission', 'part']), submissionPart);
  }

  getLearningActivityTree(id: number): Observable<LearningActivityTree> {
    return this.http.get<LearningActivityTree>(this.api.endpoint(['learning-activities', id, 'tree']));
  }

  getDistinctLearningMaterialsForCohort(cohortId: number): Observable<LearningMaterialData[]> {
    return this.http.get<LearningMaterialData[]>(this.api.endpoint(['learning-activities', 'cohorts', cohortId, 'materials']));
  }

  getAllLearningActivities(params: LearningActivityQuery & PageParams): Observable<Page<LearningActivity>> {
    return this.http.get<Page<LearningActivity>>(this.api.endpoint(['learning-activities']), {
      params: {
        ...params
      }
    });
  }

  assignLearningActivityToCohort(cohortId: number, createActivityRequest: CreateLearningActivityRequest): Observable<LearningActivity> {
    return this.http.post<LearningActivity>(this.api.endpoint(['cohorts', cohortId, 'learning-activities']), createActivityRequest);
  }

  getRecentLearningActivitiesForStudent(studentId: number, limit = 5): Observable<LearningActivity[]> {
    return this.http.get<LearningActivity[]>(this.api.endpoint(['students', studentId, 'recent-activities']), {
      params: {
        limit: limit.toString()
      }
    });
  }

  addCommentToAssignmentSubmission(submissionId: number, commentData: SubmissionComment): Observable<SubmissionComment> {
    return this.http.post<SubmissionComment>(this.api.endpoint(['learning-activities', submissionId, 'comments']), commentData);
  }

  getCommentsForAssignmentSubmission(submissionId: number): Observable<SubmissionComment[]> {
    return this.http.get<SubmissionComment[]>(this.api.endpoint(['learning-activities', submissionId, 'comments']));
  }

  addReplyToComment(submissionCommentId: number, replyData: SubmissionComment): Observable<SubmissionComment> {
    return this.http.post<SubmissionComment>(this.api.endpoint(['learning-activities', submissionCommentId, 'reply']), replyData);
  }

  getReplyContent(submissionCommentId: number): Observable<CommentReply[]> {
    return this.http.get<CommentReply[]>(this.api.endpoint(['learning-activities', submissionCommentId, 'reply']));
  }
  

}
